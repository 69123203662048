<template>
  <div>
    <el-popover placement="right" trigger="hover" :close-delay="0">
      <img :src="imgURL" alt="Example Picture" :width="imgWdith">
      <el-button slot="reference" style="margin: 6px" type="primary" @click="startProcess('print', type, givenData)" icon="">Print {{typeString}}</el-button>
    </el-popover>
    <el-dialog
      center
      :width="dialogWidth"
      :title="'Print ' + typeString"
      :visible.sync="modals.print"
      :modalAppendToBody="false">
      <div v-if="rollTypes.includes(type)">
        <card v-if="type.includes('roll')">
          <div class="row">
            <div class="col-sm-12 text-center">
              Print By
            </div>
            <div class="col-sm-12">
              <el-select filterable clearable v-model="printType" placeholder="Print By" style="width: 100%"
                         @change="filterData">
                <el-option v-for="item in printByTypes"
                           :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </div>
          </div>
        </card>
        <div class="row" v-else>
          <div class="col-sm-12 text-center">
            Ranks
          </div>
        </div>
        <el-table stripe :data="filteredData" style="width: 100%" height="40vh" v-if="filteredData.length > 0">
          <el-table-column :min-width="150" align="center">
            <template slot="header">
              <el-checkbox :checked="isChecked()" id="select_all" @change="clickSelect"></el-checkbox>
              Select
            </template>
            <template v-slot="scope">
              <el-checkbox type="checkbox" :value="checkSelected(scope.row.id)" @change="selectID(scope.row.id)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Name" :min-width="150" align="center">
            <template v-slot="scope">
              {{ scope.row && scope.row.name ? scope.row.name : 'No Name Available' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else-if="eventTypes.includes(type)">
        <card>
          <div class="row">
            <div class="col-sm-12 text-center">
              Filters
            </div>
            <div class="col-sm-4">
              <el-select filterable clearable v-model="programFilter" placeholder="Select Filter Program" style="width: 100%"
                         @change="filterData">
                <el-option v-for="item in allPrograms"
                           :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4">
              <el-select filterable clearable v-model="rankFilter" placeholder="Select Filter Rank" style="width: 100%"
                         @change="filterData">
                <el-option v-for="item in allRanks"
                           :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4">
              <el-input v-model="search" @keyup.native="filterData" placeholder="Search by name"></el-input>
            </div>
          </div>
        </card>
        <el-table stripe :data="filteredData" style="width: 100%" height="40vh">
          <el-table-column :min-width="150" align="center">
            <template slot="header">
              <el-checkbox :checked="isChecked()" id="select_all" @change="clickSelect"></el-checkbox>
              Select
            </template>
            <template v-slot="scope">
              <el-checkbox type="checkbox" :value="checkSelected(scope.row.id)" @change="selectID(scope.row.id)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Name" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.user.first_name}} {{scope.row.user.last_name}}
            </template>
          </el-table-column>
          <el-table-column label="Program" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.program.name}}
            </template>
          </el-table-column>
          <el-table-column label="Current Rank" :min-width="150" align="center">
            <template v-slot="scope">
              {{scope.row.rank.name}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else-if="type === 'curriculum'" class="text-center">
        <el-button type="primary" @click="preCurPrint(true)" icon="">Print Current Curriculum</el-button>
        <br><br>
        <el-button type="primary" @click="preCurPrint(false)" icon="">Print Next Curriculum</el-button>
        <br><br>
        <el-button v-if="stepSparring && stepSparring.pdf" type="primary" @click="downloadPDF(stepSparring.pdf, 'step_sparring', stepSparring.id)" icon="">Download Current Step Sparring</el-button>
      </div>

      <span slot="footer" class="dialog-footer">
        <div class="row">
          <div class="col-sm-2 text-center" v-if="type === 'event-curriculum'">
            <el-button type="success" @click="preCurPrint(true)" v-if="selectedData.length > 0" icon="">Print Current Curriculum</el-button>
            <br><br>
            <el-button type="success" @click="preCurPrint(false)" v-if="selectedData.length > 0" icon="">Print Next Curriculum</el-button>
          </div>
          <div class="col-sm-2" v-else>
            <el-button type="success" @click="print" icon="" v-if="selectedData.length > 0">Print {{typeString}}</el-button>
          </div>
          <div class="col-sm-3 align-content-center">
            <el-input v-if="type === 'black-belt-certs'" v-model="theme" placeholder="Theme"></el-input>
          </div>
          <div class="col-sm-5"></div>
          <div class="col-sm-2 text-right align-content-center">
            <el-button type="danger" @click="closePrintModal('print')" icon="">Close</el-button>
          </div>
        </div>
    </span>
    </el-dialog>
    <el-dialog
      center
      :before-close="handleClose"
      title="Error"
      :visible.sync="modals.error"
      :modalAppendToBody="false">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :before-close="handleClose"
      title="Success"
      :visible.sync="modals.success"
      :modalAppendToBody="false">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      :visible.sync="loading"
      :modalAppendToBody="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="200px"
      height="200px"
      top="calc(50vh - 100px)">
      <div class="text-center">
        <el-progress type="circle" v-if="precentage >= 100" :percentage="precentage" status="success"></el-progress>
        <el-progress type="circle" v-else :percentage="precentage"></el-progress>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { Dialog, Option, Select, Input, Checkbox, Button, Table, TableColumn, Switch, Progress, Popover } from 'element-ui';
import {nyalaNormalBase64} from "./Admin/Fonts/nyala-normal";
import {lastNinjaBase64} from "./Admin/Fonts/lastninja";
import {nyalaBoldBase64} from "./Admin/Fonts/nyala-bold";
import {parchmentNormalBase64} from "./Admin/Fonts/Parchment MF-normal";
import {base64RedCert} from "./Admin/Images/RedCertificate";
import {base64PrelimCert} from "./Admin/Images/PrelimCertificate";
import {base64FoundationCert} from "./Admin/Images/FoundationCertificate";
import {base64BlackCert} from "./Admin/Images/BlackCertificate";
import RanksAPIService from "../../../servicehandlers/RanksAPIService";
import ProgramsAPIService from "../../../servicehandlers/ProgramsAPIService";
import PoomsaeAPIService from "../../../servicehandlers/PoomsaeAPIService";
import KicksAPIService from "../../../servicehandlers/KicksAPIService";
import SelfDefenseTechniquesAPIService from "../../../servicehandlers/SelfDefenseTechniquesAPIService";
import KickCombosAPIService from "../../../servicehandlers/KickCombosAPIService";
import TechniquesAPIService from "../../../servicehandlers/TechniquesAPIService";
import OutlineAPIService from "../../../servicehandlers/OutlinesAPIService";
import ModifiersAPIService from "../../../servicehandlers/ModifiersAPIService";
import PenaltiesAPIService from "../../../servicehandlers/PenaltiesAPIService";
import BoardsAPIService from "../../../servicehandlers/BoardsAPIService";
import BreakingTechniquesAPIService from "../../../servicehandlers/BreakingTechniquesAPIService";
import SupportOptionsAPIService from "../../../servicehandlers/SupportOptionsAPIService";
import DirectionsAPIService from "../../../servicehandlers/DirectionsAPIService";
import TestingTypesAPIService from "../../../servicehandlers/TestingTypesAPIService";
import RankTypesAPIService from "../../../servicehandlers/RankTypesAPIService";
import MotionsAPIService from "../../../servicehandlers/MotionsAPIService";
import StepSparringAPIService from "../../../servicehandlers/StepSparringAPIService";
import FileAPIService from "../../../servicehandlers/FileHandler";

const ranksAPIService = new RanksAPIService();
const programAPIService = new ProgramsAPIService()
const poomsaeAPIService = new PoomsaeAPIService()
const kicksAPIService = new KicksAPIService()
const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService()
const kickCombosAPIService = new KickCombosAPIService()
const techniqueAPIService = new TechniquesAPIService()
const outlineAPIService = new OutlineAPIService();
const modifiersAPIService = new ModifiersAPIService();
const penaltiesAPIService = new PenaltiesAPIService();
const boardsAPIService = new BoardsAPIService();
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
const supportOptionsAPIService = new SupportOptionsAPIService();
const directionsAPIService = new DirectionsAPIService();
const testingTypesAPIService = new TestingTypesAPIService()
const rankTypesAPIService = new RankTypesAPIService()
const motionsAPIService = new MotionsAPIService();
const stepSparringAPIService = new StepSparringAPIService()
const fileAPI = new FileAPIService();

export default {
  name: "Outline",
  components: {
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
    [Progress.name]: Progress,
    [Popover.name]: Popover,
  },
  props: {
    type: String,
    givenData: Array,
    printName: String,
  },
  data() {
    return {
      loading: false,
      modals: {
        error: false,
        success: false,
        print: false,
      },
      error_txt: '',
      success_txt: '',
      typeString: '',
      filteredData: [],
      programFilter: null,
      rankFilter: null,
      allRanks: [],
      allPrograms: [],
      search: '',
      selectAll: false,
      selectedData: [],
      categories: [
        {
          id: 'spirit',
          name: 'Martial Arts Spirit',
          items: [
            {
              name: 'Presentation'
            }
          ],
        },
        {
          id: 'kicks',
          name: 'Kicking',
          items: [],
        },
        {
          id: 'kick_combos',
          name: 'Kicking Combos',
          items: [],
        },
        {
          id: 'poomsae',
          name: 'Poomsae',
          items: [],
        },
        {
          id: 'techniques',
          name: 'Techniques',
          items: [],
        },
        {
          id: 'self_defense',
          name: 'Self Defence',
          items: [],
        },
      ],
      eventTypes: ['event-test-sheets', 'foundation-certs', 'color-belt-certs', 'prelim-certs', 'black-belt-certs', 'event-scores', 'event-outlines', 'event-curriculum', 'bbt-test-sheets'],
      rollTypes: ['event-roll-sheet', 'event-call-sheet', 'bbt-roll-sheet', 'bbt-call-sheet'],
      theme: '',
      checkbox_modifiers: [],
      penalties: [],
      dropdown_boards: [],
      dropdown_techniques: [],
      dropdown_support_options: [],
      allDirections: [],
      printByGroup: false,
      printByTypes: [
        {
          id: 0,
          name: 'Program',
        },
        {
          id: 1,
          name: 'Rank',
        },
        {
          id: 2,
          name: 'Groups',
        },
        {
          id: 3,
          name: 'Rank Level',
        },
      ],
      printType: null,
      dialogWidth: '50%',
      testing_types: [],
      rank_types: [],
      precentage: 0,
      imgURL: '',
      imgWdith: '',
      printCurrent: null,
      stepSparring: null,
    }
  },
  computed: {
    isChecked(){
      return () => {
        return this.selectAll
      }
    }
  },
  mounted(){
    this.getTypeString()
    //console.clear()
  },
  methods: {
    //          Get Functions
    getTypeString(){
      switch (this.type){
        case 'event-test-sheets':
          this.typeString = 'Testing Sheets'
          this.imgURL = '/static/img/testing-sheet-example.png'
          this.imgWdith = '150px'
          break
        case 'foundation-certs':
          this.typeString = 'Foundations Certificates'
          this.imgURL = '/static/img/foundations-cert-example.jpg'
          this.imgWdith = '210px'
          break
        case 'color-belt-certs':
          this.typeString = 'Color Belt Certificates'
          this.imgURL = '/static/img/color-belt-cert-example.jpg'
          this.imgWdith = '210px'
          break
        case 'prelim-certs':
          this.typeString = 'Prelim Certificates'
          this.imgURL = '/static/img/prelim-cert-example.jpg'
          this.imgWdith = '210px'
          break
        case 'black-belt-certs':
          this.typeString = 'Black Belt Certificates'
          this.imgURL = '/static/img/black-belt-cert-example.jpg'
          this.imgWdith = '210px'
          break
        case 'event-scores':
          this.typeString = 'Scores'
          this.imgURL = '/static/img/score-sheet-example.jpg'
          this.imgWdith = '210px'
          break
        case 'event-outlines':
          this.typeString = 'Outlines'
          this.imgURL = '/static/img/outline-sheet-example.jpg'
          this.imgWdith = '210px'
          break
        case 'event-roll-sheet':
          this.typeString = 'Roll Sheets'
          this.imgURL = '/static/img/roll-sheet-example.jpg'
          this.imgWdith = '150px'
          break
        case 'bbt-call-sheet':
          this.typeString = 'BBT Call Sheets'
        case 'event-call-sheet':
          if (!this.typeString) this.typeString = 'Call Sheets'
          this.imgURL = '/static/img/call-sheet-example.jpg'
          this.imgWdith = '150px'
          break
        case 'test-sheet':
          this.typeString = 'Test Sheet'
          this.imgURL = '/static/img/testing-sheet-example.png'
          this.imgWdith = '150px'
          break
        case 'curriculum':
        case 'event-curriculum':
          this.typeString = 'Curriculum'
          this.imgURL = '/static/img/curriculum-example.jpg'
          this.imgWdith = '150px'
          break
        case 'bbt-test-sheets':
          this.typeString = 'BBT Testing Sheets'
          this.imgURL = '/static/img/bbt-testing-example.jpg'
          this.imgWdith = '150px'
          break
        case 'bbt-roll-sheet':
          this.typeString = 'BBT Roll Sheets'
          //this.imgURL = '/static/img/roll-sheet-example.jpg'
          this.imgWdith = '150px'
          break
        case 'bbt-curriculum':
          this.typeString = 'BBT Curriculum'
          //this.imgURL = '/static/img/curriculum-example.jpg'
          this.imgWdith = '150px'
          break
        default:
          this.typeString = 'Unknown'
          break
      }
    },
    getRanks() {
      return ranksAPIService.getRanks(this.$router)
        .then((ranks) => {
          this.allRanks = ranks
        })
        .catch((error) => {
          this.error_txt = 'Error getting ranks: ' + error;
          this.openModal('error');
        });
    },
    getPrograms(){
      return programAPIService.getPrograms(this.$router)
        .then((response) => {
          this.allPrograms = response
        }).catch((error) => {
          this.error_txt = 'Error getting programs: ' + error;
          this.openModal('error');
        });
    },
    getPoomsaeByRankId(id){
      return poomsaeAPIService.getPoomsae(this.$router)
        .then((poomsae_resp) => {

          poomsae_resp.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });

          for (const category of this.categories) {
            if (category.id === 'poomsae') {
              category.dropdown_list = [];
              for (const poomsae of poomsae_resp) {
                poomsae.value = poomsae.id;
                poomsae.text = poomsae.description;
                poomsae.name = poomsae.description;
                category.dropdown_list.push(poomsae);
              }
              break;
            }
          }
          return poomsaeAPIService.getPoomsaeByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'poomsae') {
              category.items = [];
              for (const item of response) {
                for (const poomsae of category.dropdown_list){
                  if (poomsae.id === item.poomsae_id){
                    if (item.tested){
                      item.name = poomsae.name
                      item.id = poomsae.id
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting poomsae: ' + error;
          this.openModal('error');
        });
    },
    getKicksByRankId(id){
      return kicksAPIService.getKick(this.$router)
        .then((kicks_resp) => {

          kicks_resp.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });

          for (const category of this.categories) {
            if (category.id === 'kicks') {
              category.dropdown_list = [];
              for (const kick of kicks_resp) {
                kick.value = kick.id;
                kick.text = kick.description;
                category.dropdown_list.push(kick);
              }
              break;
            }
          }
          return kicksAPIService.getKickByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'kicks') {
              category.items = [];
              for (const item of response) {
                for (const kick of category.dropdown_list){
                  if (kick.id === item.kick_id){
                    if (item.tested){
                      item.name = kick.name
                      item.id = kick.id
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting kicks: ' + error;
          this.openModal('error');
        });
    },
    getSelfDefByRankId(id){
      return selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
        .then((response) => {

          response.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });

          for (const category of this.categories) {
            if (category.id === 'self_defense') {
              category.dropdown_list = [];
              for (const item of response) {
                item.value = item.id;
                item.text = item.description;
                item.name = item.description;
                category.dropdown_list.push(item);
              }
              break;
            }
          }
          return selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'self_defense') {
              category.items = [];
              for (const item of response) {
                for (const self_def of category.dropdown_list){
                  if (self_def.id === item.self_defense_techniques_id){
                    if (item.tested){
                      item.name = self_def.name
                      item.id = self_def.id
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting Self Defense Items: ' + error;
          this.openModal('error');
        });
    },
    getKickCombosByRankId(id){
      return kickCombosAPIService.getKickCombos(this.$router)
        .then((response) => {
          response.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });
          for (const category of this.categories) {
            if (category.id === 'kick_combos') {
              category.dropdown_list = [];
              for (const item of response) {
                item.value = item.id;
                item.text = item.description;
                item.name = item.description;
                category.dropdown_list.push(item);
              }
              break;
            }
          }
          return kickCombosAPIService.getKickCombosByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'kick_combos') {
              category.items = [];
              for (let item of response){
                for (const kick_combo of category.dropdown_list){
                  if (kick_combo.id === item.kick_combo_id){
                    if (item.tested){
                      item.name = kick_combo.name
                      item.id = kick_combo.id
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting Kick Combos: ' + error;
          this.openModal('error');
        });
    },
    getTechsByRankId(id){
      return techniqueAPIService.getTechnique(this.$router)
        .then((response) => {
          for (const category of this.categories) {
            if (category.id === 'techniques') {
              category.dropdown_list = [];
              for (const item of response) {
                item.value = item.id;
                item.text = item.description;
                item.name = item.description;
                category.dropdown_list.push(item);
              }
              break;
            }
          }
          return techniqueAPIService.getTechniqueByRank(id, this.$router)
        })
        .then((response) => {
          // now sort through the items
          for (const category of this.categories) {
            if (category.id === 'techniques') {
              category.items = [];
              for (const item of response) {
                for (const tech of category.dropdown_list){
                  if (tech.id === item.technique_id){
                    if (item.tested){
                      item.name = tech.name
                      item.id = tech.id
                      category.items.push(item);
                    }
                  }
                }
              }
              break;
            }
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting techniques: ' + error;
          this.openModal('error');
        });
    },
    getModifiers() {
      return modifiersAPIService.getModifiers(this.$router)
        .then((modifiers) => {
          for (const modifier of modifiers) {
            modifier.text = modifier.description;
            modifier.value = modifier.id;
            this.checkbox_modifiers.push(modifier);
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting modifiers for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getPenalties() {
      return penaltiesAPIService.getPenalties(this.$router)
        .then((penalties) => {
          this.penalties = penalties;
        })
        .catch((error) => {
          this.error_txt = 'Error getting penalties: ' + error;
          this.openModal('error');
        });
    },
    getBoards() {
      return boardsAPIService.getBoards(this.$router)
        .then((boards) => {
          for (let i = 0; i < boards.length; i += 1) {
            boards[i].text = boards[i].name;
            boards[i].value = boards[i].id;
          }
          const tmpBoard = {
            value: null,
            text: 'N/A',
            id: null,
            name: 'N/A'
          };
          boards.unshift(tmpBoard);
          this.dropdown_boards = boards;
        })
        .catch((error) => {
          this.error_txt = 'Error getting boards for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getTechniques() {
      return breakingTechniquesAPIService.getBreakingTechniques(this.$router)
        .then((techniques) => {
          for (const technique of techniques) {
            technique.text = technique.name;
            technique.value = technique.id;
          }
          const tmpTechnique = {
            text: '-------',
            value: null,
            id: null,
            name: '-------',
          };
          techniques.unshift(tmpTechnique);
          this.dropdown_techniques = techniques;
        })
        .catch((error) => {
          this.error_txt = 'Error getting techniques for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getSupportOptions() {
      return supportOptionsAPIService.getSupportOptions(this.$router)
        .then((modifiers) => {
          for (const modifier of modifiers) {
            modifier.text = modifier.description;
            modifier.value = modifier.id;
            this.dropdown_support_options.push(modifier);
          }
        })
        .catch((error) => {
          this.error_txt = 'Error getting modifiers for dropdown: ' + error;
          this.openModal('error');
        });
    },
    getAllDirections() {
      return directionsAPIService.getAllDirections(this.$router)
        .then((directions) => {
          for (const dir of directions) {
            dir.text = dir.name;
            dir.value = dir.id;
          }
          this.allDirections = directions;
        })
        .catch((error) => {
          this.error_txt = 'Error getting directions: ' + error;
          this.openModal('error');
        });
    },
    getTestingTypes(){
      return testingTypesAPIService.getTestingTypes(this.$router)
        .then((response) => {
          this.testing_types = response
        })
        .catch((e) => {
          this.error_txt = 'Error getting testing types' + e
          this.openModal('error')
        })
    },
    getRankTypes(){
      return rankTypesAPIService.getRankTypes(this.$router)
        .then((response) => {
          this.rank_types = response
        })
        .catch((e) => {
          this.error_txt = "Error getting rank types: " + e
          this.openModal('error')
        })
    },
    getStepSparring(){
      return stepSparringAPIService.getCurrentStepSparrings(this.$router)
        .then((response) => {
          this.stepSparring = response
        })
    },


    //          Page Functions
    startProcess(name, type, data) {
      if (this.allRanks.length <= 0){
        this.getRanks()
      }
      if (this.allPrograms.length <= 0){
        this.getPrograms()
      }
      //console.log(type)
      //console.log(data)
      if (this.eventTypes.includes(type)){
        this.filteredData = data
        this.dialogWidth = '90%'
        this.modals[name] = true;
        this.clickSelect()
      } else {
        switch (type){
          case 'event-roll-sheet':
            this.getTestingTypes()
            this.getRankTypes()
            this.modals[name] = true;
            break
          case 'bbt-call-sheet':
          case 'event-call-sheet':
            for (let order of data){
              if (!this.filteredData.some(e => e.id === order.rank.id)){
                let temp = {
                  id: order.rank.id,
                  name: order.rank.name,
                  order: order.rank.order
                }
                this.filteredData.push(temp)
              }
            }
            this.filteredData.sort((a, b) => a.order - b.order);
            this.clickSelect()
            this.modals[name] = true;
            break
          case 'test-sheet':
            this.printTestingSheets()
            break
          case 'curriculum':
            this.getStepSparring()
            this.modals[name] = true;
            break
          case 'bbt-roll-sheet':
            this.getTestingTypes()
            this.getRankTypes()
            this.modals[name] = true;
            break
          default:
            this.error_txt = "Unknown Print Type: " + this.type
            this.openModal('error')
            return
        }
      }
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {}
    },
    closePrintModal(name) {
      this.selectAll = false
      this.selectedData = [];
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },


    //          Data Functions
    filterData(){
      if (this.eventTypes.includes(this.type)){
        this.filteredData = this.givenData.filter(
          (data) =>
            !this.search ||
            (data.user.first_name.toLowerCase() + data.user.last_name.toLowerCase()).includes(this.search.toLowerCase())
        ).filter(
          (data) =>
            !this.programFilter ||
            (data.program.id === this.programFilter)
        ).filter(
          (data) =>
            !this.rankFilter ||
            (data.rank.id === this.rankFilter)
        )
      } else {
        switch (this.type){
          case 'bbt-roll-sheet':
          case 'event-roll-sheet':
            this.filteredData = []
            for (let order of this.givenData){
              switch (this.printByTypes[this.printType].name){
                case 'Program':
                  if (!this.filteredData.some(e => e.id === order.program.id)){
                    let temp = {
                      id: order.program.id,
                      name: order.program.name,
                    }
                    this.filteredData.push(temp)
                  }
                  break
                case 'Rank':
                  if (!this.filteredData.some(e => e.id === order.rank.id)){
                    let temp = {
                      id: order.rank.id,
                      name: order.rank.name,
                    }
                    this.filteredData.push(temp)
                  }
                  break
                case 'Groups':
                  if (!this.filteredData.some(e => e.id === order.testing_type_id)){
                    let typeName = ''
                    for (let type of this.testing_types){
                      if (order.testing_type_id === type.id){
                        typeName = type.name
                        break
                      }
                    }
                    let temp = {
                      id: order.testing_type_id,
                      name: typeName,
                    }
                    this.filteredData.push(temp)
                  }
                  break
                case 'Rank Level':
                  if (!this.filteredData.some(e => e.id === order.rank.rank_type_id)){
                    let levelName = ''
                    for (let level of this.rank_types){
                      if (order.rank.rank_type_id === level.id){
                        levelName = level.name
                        break
                      }
                    }
                    let temp = {
                      id: order.rank.rank_type_id,
                      name: levelName,
                    }
                    this.filteredData.push(temp)
                  }
                  break
              }
            }
            break
        }
        if (this.selectAll){
          this.clickSelect()
          this.clickSelect()
        } else {
          this.clickSelect()
        }
      }
    },
    clickSelect() {
      this.selectAll = !this.selectAll
      if (this.selectAll === false) {
        this.selectedData = [];
      } else {
        for (const data of this.filteredData) {
          if (data) {
            this.selectedData.push(data.id);
          }
        }
      }
    },
    checkSelected(id){
      return this.selectedData.includes(id)
    },
    selectID(id){
      if (this.selectedData.includes((id))) {
        let index = this.selectedData.indexOf(id)
        this.selectedData.splice(index, 1)
      } else {
        this.selectedData.push(id)
      }
    },


    //          Print Functions
    preCurPrint(val){
      this.printCurrent = val
      this.print()
    },
    async print(){
      this.loading = true
      this.precentage = 0
      /*console.log(this.typeString, this.type)*/
      switch (this.type){
        case 'event-test-sheets':
          await this.printTestingSheets()
          break
        case 'foundation-certs':
          await this.printFoundationCerts('foundation')
          break
        case 'color-belt-certs':
          await this.printFoundationCerts('red')
          break
        case 'prelim-certs':
          await this.printFoundationCerts('prelim')
          break
        case 'black-belt-certs':
          await this.printFoundationCerts('black')
          break
        case 'event-scores':
          await this.getModifiers()
          await this.getPenalties()
          await this.getBoards()
          await this.getTechniques()
          await this.getSupportOptions()
          await this.getAllDirections()
          await this.printScores()
          break
        case 'event-outlines':
          await this.getModifiers()
          await this.getPenalties()
          await this.getBoards()
          await this.getTechniques()
          await this.getSupportOptions()
          await this.getAllDirections()
          await this.printOutlines()
          break
        case 'event-roll-sheet':
          await this.printRollSheets()
          break
        case 'event-call-sheet':
          await this.printCallSheets()
          break
        case 'curriculum':
          await this.printCurriculum()
          break
        case 'event-curriculum':
          await this.printCurriculum()
          break
        case 'bbt-test-sheets':
          await this.printBBTestingSheets()
          break
        case 'bbt-roll-sheet':
          await this.printBBTRollSheets()
          break
        default:
          this.error_txt = "Doesn't work yet"
          this.loading = false
          this.openModal('error')
          return
      }
      this.loading = false
    },
    async printTestingSheets() {
      const doc = new JsPDF({
        orientation: "portrait",
        format: 'letter',
      });
      let empty = true
      let count = 0

      function addFontToPDF(pdf) {
        pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
        pdf.addFont('nyala.ttf', 'nyala', 'normal');
        pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
        pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
        pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
        pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
        pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
        pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
      }

      addFontToPDF(doc)

      for (let order of this.givenData){
        let index = this.givenData.indexOf(order) + 1
        this.precentage = (index / this.givenData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        if (this.checkSelected(order.id) || this.givenData.length === 1) {
          empty = false
          count++
          doc.setFontSize('10');
          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();
          const halfPage = pageWidth / 2;
          let x = 10;
          let y = 10;

          function printTopInfo(rank){
            doc.setFontSize('10')
            y = 10
            x = 10
            doc.setFont('times', 'bold')
            let textWidth = doc.getTextWidth('Student Name:')
            doc.text("Student Name:", x, y);
            doc.setFont('nyala', 'normal')
            doc.text(order.user.first_name + " " + order.user.last_name, x + (textWidth + 1), y);
            x = pageWidth - 10
            textWidth = doc.getTextWidth(rank.name)
            doc.setFont('times', 'bold')
            doc.text("Current Rank:", x - (textWidth + 1), y, 'right')
            doc.setFont('nyala', 'normal')
            doc.text(rank.name, x, y, 'right')
            y += 1
            x = 10
            doc.setFont('times', 'bold')
            textWidth = doc.getTextWidth('Student Name:')
            doc.line(x + textWidth, y, halfPage, y);
            doc.setFont('nyala', 'normal')
            textWidth = doc.getTextWidth(rank.name)
            doc.line(pageWidth - textWidth - 10, y, pageWidth - 10, y);
            y += 10;
          }

          printTopInfo(order.rank)
          doc.setFont('times', 'bold')
          doc.setFontSize('16');
          let titleString = 'Beyond Sports Taekwondo Testing Card'
          x = halfPage
          doc.text(titleString, x, y, 'center');
          y += 5
          let rectSize = 175
          x = halfPage - (rectSize / 2)
          doc.setFillColor(230, 230, 230);
          doc.rect(x, y, rectSize, rectSize / 8, 'DF')
          x = pageWidth / 3
          let boxy = y + 6
          doc.setFontSize('10')
          doc.setFont('nyala', 'normal')
          doc.text('Not Ready: Execution is below capability', x, boxy, 'center')
          x += x
          doc.text('Good: Execution above expectation', x, boxy, 'center')
          boxy += 6
          x = pageWidth / 3
          doc.text('Getting There: Execution is acceptable for capability', x, boxy, 'center')
          x += x
          doc.text('Excellent: Blown away by Execution', x, boxy, 'center')
          boxy += 6
          x = halfPage
          doc.setFontSize('8')
          doc.text('Note: Excellent does not mean perfect-There is always room to grow!', x, boxy, 'center')
          y += 9 + (rectSize / 8)
          x = 10

          for (let category of this.categories) {
            switch (category.id) {
              case "poomsae":
                await this.getPoomsaeByRankId(order.rank.id)
                break
              case "kicks":
                await this.getKicksByRankId(order.rank.id)
                break
              case "self_defense":
                await this.getSelfDefByRankId(order.rank.id)
                break
              case "kick_combos":
                await this.getKickCombosByRankId(order.rank.id)
                break
              case "techniques":
                await this.getTechsByRankId(order.rank.id)
                break
            }
            if (category.items.length > 0) {
              for (let item of category.items) {
                if (y > (pageHeight - 21)){
                  doc.addPage()
                  printTopInfo(order.rank)
                }
                if (category.items.indexOf(item) === 0){
                  x = 10
                  doc.setFont('Times-Roman', 'bolditalic')
                  doc.setFontSize('14')
                  doc.text(category.name, x, y)
                }
                x = 15
                y += 7
                doc.setFontSize('12')
                rectSize = 6
                doc.rect(x, y - 4, rectSize + 2, rectSize)
                doc.setFont('nyala', 'normal')
                let originalY = y
                if (doc.getTextWidth(item.name) >= 95){
                  let splitName = item.name.split(' ')
                  let builtName = splitName[0] + ' '
                  for (let i = 1; i < splitName.length; i++){
                    if (doc.getTextWidth(builtName + splitName[i] + ' ') >= 80){
                      doc.text(builtName, x + 15, y)
                      y += 5
                      builtName = splitName[i] + ' '
                    } else {
                      builtName += splitName[i] + ' '
                    }
                  }
                  doc.text(builtName, x + 15, y)
                } else {
                  doc.text(item.name, x + 15, y)
                }

                doc.setFontSize('11')
                doc.setFont('Times-Roman', 'bolditalic')
                doc.text("Not Ready", x + 95, originalY)
                doc.text("Getting There", x + 120, originalY)
                doc.text("Good", x + 150, originalY)
                doc.text("Excellent", x + 170, originalY)
                x = 15 + 15
                y += 10
                doc.line(x, y, pageWidth - 15, y);
                y += 6
              }
            } else {
              y += 10
            }
          }

          rectSize = 130

          if (y > (pageHeight - (rectSize / 8) + 10)){
            doc.addPage()
            printTopInfo(order.rank)
          }
          let paddedRect = halfPage - (rectSize / 2) + 3
          x = halfPage - (rectSize / 2)
          doc.setFillColor(230, 230, 230);
          doc.rect(x, y, rectSize, rectSize / 8, 'DF')
          doc.setFont('Times-Roman', 'bold')
          doc.setFontSize('10')
          y += 6
          x = paddedRect
          doc.text('Date:', x, y)
          x = halfPage
          doc.text('Grader:', x, y)
          y += 1
          let textWidth = doc.getTextWidth('Date:')
          doc.line(paddedRect + textWidth, y, halfPage - 3, y);
          textWidth = doc.getTextWidth('Grader:')
          doc.line(halfPage + textWidth, y, halfPage + (rectSize / 2) - 3, y);
          y += 5
          x = paddedRect
          doc.text('Next Test Date:', x, y)
          x = halfPage
          doc.text('Advanced to:', x, y)
          textWidth = doc.getTextWidth('Advanced to:')
          doc.setFont('nyala', 'normal')
          doc.text(order.nextRank.name, x + textWidth + 1, y)
          y += 1
          doc.setFont('Times-Roman', 'bold')
          textWidth = doc.getTextWidth('Next Test Date:')
          doc.line(paddedRect + textWidth, y, halfPage - 3, y);
          textWidth = doc.getTextWidth('Advanced to:')
          doc.line(halfPage + textWidth, y, halfPage + (rectSize / 2) - 3, y);

          if (count < this.selectedData.length){
            doc.addPage();
          }
        }
      }

      if (!empty) {
        let fileName = this.printName + " Testing Sheets.pdf"
        doc.save(fileName);
      } else {
        this.error_txt = "No Competitors selected"
        this.openModal('error')
      }
    },
    async printBBTestingSheets() {
      const doc = new JsPDF({
        orientation: "portrait",
        format: 'letter',
      });
      let empty = true
      let count = 0

      function addFontToPDF(pdf) {
        pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
        pdf.addFont('nyala.ttf', 'nyala', 'normal');
        pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
        pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
        pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
        pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
        pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
        pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
      }

      addFontToPDF(doc)

      for (let order of this.givenData){
        let index = this.givenData.indexOf(order) + 1
        this.precentage = (index / this.givenData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        if (this.checkSelected(order.id) || this.givenData.length === 1) {
          empty = false
          count++
          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();
          const halfPage = pageWidth / 2;
          let x = 20;
          let y = 20;

          function printTopInfo(rank, tape, trail){
            x = 20
            doc.setFontSize('20')
            doc.setFont('times', 'bold')
            doc.text(tape, x + 10, y)
            doc.setFont('times', 'normal')
            doc.text(trail, pageWidth - 30, y, 'right')
            y += 10

            let textWidth = doc.getTextWidth('Name:')
            doc.setFontSize('14')
            doc.setFont('times', 'bold')
            doc.text("Name:", x, y);
            doc.setFont('nyala', 'normal')
            doc.text(order.user.first_name + " " + order.user.last_name, x + (textWidth + 1), y);
            x = pageWidth - 20
            textWidth = doc.getTextWidth(rank.name)
            doc.setFont('times', 'bold')
            doc.text("Current Rank:", x - (textWidth + 1), y, 'right')
            doc.setFont('nyala', 'normal')
            doc.text(rank.name, x, y, 'right')
            y += 1
            doc.setFont('times', 'bold')
            textWidth = doc.getTextWidth('Name:')
            x = 20
            doc.line(x + textWidth, y, halfPage, y);
            doc.setFont('nyala', 'normal')
            textWidth = doc.getTextWidth(rank.name)
            doc.line(pageWidth - textWidth - 20, y, pageWidth - 20, y);
          }

          this.categories= [
            {
              id: 'kicks',
              name: 'Kicking',
              items: [],
            },
            {
              id: 'kick_combos',
              name: 'Kicking Combos',
              items: [],
            },
            {
              id: 'poomsae',
              name: 'Poomsae',
              items: [],
            },
            {
              id: 'techniques',
              name: 'Techniques',
              items: [],
            },
            {
              id: 'self_defense',
              name: 'Self Defence',
              items: [],
            },
            {
              id: 'sparring',
              name: 'Sparring',
              items: [
                {
                  name: 'Olympic Sparring'
                },
                {
                  name: 'Free Sparring'
                },
              ],
            },
          ]

          for (let category of this.categories) {
            let height = 21;
            height += ((category.items.length / 2) + 1) * 10
            height += 28
            if (y > (pageHeight - height - 20)){
              y = 20
              x = 20
              doc.addPage()
            }
            let rectSize
            switch (category.id) {
              case "poomsae":
                await this.getPoomsaeByRankId(order.rank.id)
                break
              case "kicks":
                await this.getKicksByRankId(order.rank.id)
                break
              case "self_defense":
                await this.getSelfDefByRankId(order.rank.id)
                break
              case "kick_combos":
                await this.getKickCombosByRankId(order.rank.id)
                break
              case "techniques":
                await this.getTechsByRankId(order.rank.id)
                break
            }
            let startY = y + 20
            let left = true
            let lastSide = left
            if (category.items.length > 0) {
              printTopInfo(order.rank, category.name, 'Trial 1')
              for (let item of category.items) {
                y += 10
                if (!left || (category.items.indexOf(item) >= category.items.length / 2)){
                  x = halfPage + 5
                  left = false
                } else {
                  x = 15
                }
                if (left !== lastSide){
                  y = startY
                }
                lastSide = left

                doc.setFontSize('12')
                rectSize = 8
                doc.line(x, y + 2, x + rectSize, y + 2);
                doc.rect(x + rectSize, y - 4, rectSize, rectSize - 2)
                doc.setFont('nyala', 'normal')
                let originalY = y
                if (doc.getTextWidth(item.name) >= 95){
                  let splitName = item.name.split(' ')
                  let builtName = splitName[0] + ' '
                  for (let i = 1; i < splitName.length; i++){
                    if (doc.getTextWidth(builtName + splitName[i] + ' ') >= 80){
                      doc.text(builtName, x + 20, y)
                      y += 5
                      builtName = splitName[i] + ' '
                    } else {
                      builtName += splitName[i] + ' '
                    }
                  }
                  doc.text(builtName, x + 20, y)
                } else {
                  doc.text(item.name, x + 20, y)
                }
              }
            }
            y += 10

            if (category.items.length > 1 && category.items.length % 2){
              y += 10
            }
            rectSize = 160
            doc.setFillColor(256, 256, 256);
            let paddedRect = halfPage - (rectSize / 2) + 3
            x = halfPage - (rectSize / 2)
            doc.rect(x, y, rectSize, rectSize / 8, 'DF')
            y += 3
            doc.text("Grader Comments:", x + 1, y + 1)
            y += (rectSize / 8) + 5

            doc.setFontSize('14')
            doc.setFont('times', 'bold')
            let textWidth = doc.getTextWidth('Grader:')
            doc.text('Grader:', x + 10, y)
            doc.line(x + 10 + textWidth, y + 1, halfPage, y + 1);
            textWidth = doc.getTextWidth('Date:')
            doc.text('Date:', 10 + halfPage, y)
            doc.line(10 + halfPage + textWidth, y + 1, pageWidth - 30, y + 1);
            y += 25
          }

          if (count < this.selectedData.length){
            doc.addPage();
          }
        }
      }

      if (!empty) {
        let fileName = this.printName + " Testing Sheets.pdf"
        doc.save(fileName);
      } else {
        this.error_txt = "No Competitors selected"
        this.openModal('error')
      }
    },
    async printCurriculum() {
      // get kicks and motions for the kick combos
      let all_motions = null;
      try {
        all_motions = await motionsAPIService.getMotion(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting motions: ' + e;
        this.openModal('error');
        return;
      }
      let all_kicks = null;
      try {
        all_kicks = await kicksAPIService.getKick(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting kicks: ' + e;
        this.openModal('error');
        return;
      }
      let all_kick_combos = null;
      try {
        all_kick_combos = await kickCombosAPIService.getKickCombos(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting kick combos: ' + e;
        this.openModal('error');
        return;
      }
      let all_poomsae = null;
      try {
        all_poomsae = await poomsaeAPIService.getPoomsae(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting poomsae: ' + e;
        this.openModal('error');
        return;
      }
      let all_techs = null;
      try {
        all_techs = await techniqueAPIService.getTechnique(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting techniques: ' + e;
        this.openModal('error');
        return;
      }
      let all_self_defs = null;
      try {
        all_self_defs = await selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
      } catch (e) {
        this.error_txt = 'Error getting self defense: ' + e;
        this.openModal('error');
        return;
      }

      const date = moment().format('MM-DD-YYYY');
      // Get the width of the page
      const doc = new JsPDF({
        orientation: "portrait",
        format: 'letter',
      });
      doc.setFontSize('12');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;

      let fileName = ''
      let endOfFileName = ''
      // do this in two page segments
      for (let order of this.givenData){
        let index = this.givenData.indexOf(order) + 1
        this.precentage = (index / this.givenData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        let rank
        if (this.printCurrent){
          rank = order.rank
          endOfFileName = ' Current Curriculum.pdf'
        } else {
          rank = order.nextRank
          endOfFileName = ' Next Curriculum.pdf'
        }
        if (!rank){
          continue
        }

        let x = 10;
        let y = 5;
        doc.text(date, x, y);
        y += 7;
        doc.setFontSize('12');
        doc.text('Beyond Sports Taekwondo Curriculum', x, y);
        y += 7;

        doc.setFontSize('12');
        doc.text(rank.name, x, y);
        doc.text(order.user.first_name + ' ' + order.user.last_name, halfPage, y)
        doc.setFontSize('8');
        y += 7;

        doc.setFontSize('12');
        doc.text('Kicking', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let rank_kicks = null;
        try {
          rank_kicks = await kicksAPIService.getKickByRank(rank.id, this.$router)
          for (let item of rank_kicks){
            for (let kick of all_kicks){
              if (kick.id === item.kick_id){
                if (y > (pageHeight - 10)){
                  doc.addPage()
                }
                doc.text(kick.description, x + 5, y);
                y += 5;
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Error getting kicks by userRank: ' + e;
          this.openModal('error');
          return;
        }

        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Kicking Combos', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let rank_kick_combos = null;
        try {
          rank_kick_combos = await kickCombosAPIService.getKickCombosByRank(rank.id, this.$router)
          for (let item of rank_kick_combos){
            for (let kick_combo of all_kick_combos){
              if (kick_combo.id === item.kick_combo_id){
                if (y > (pageHeight - 10)){
                  doc.addPage()
                }
                doc.text(kick_combo.description, x + 5, y);
                y += 5;
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Error getting kick combos: ' + e;
          this.openModal('error');
          return;
        }

        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Poomsae', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;

        let poomsae_by_rank = null;
        try {
          poomsae_by_rank = await poomsaeAPIService.getPoomsaeByRank(rank.id, this.$router);
          for (let item of poomsae_by_rank){
            for (let poomsae of all_poomsae){
              if (poomsae.id === item.poomsae_id){
                if (y > (pageHeight - 10)){
                  doc.addPage()
                }
                if (poomsae_by_rank.indexOf(item) === 0) {
                  doc.text('Current: ' + poomsae.description, x + 5, y);
                  y += 5;
                } else {
                  doc.text('Previous: ' + poomsae.description, x + 5, y);
                  y += 5;
                }
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Unresolved data error getting poomsae to ranks: ';
          this.openModal('error');
        }

        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Techniques', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let techniques_by_rank = null;
        try {
          techniques_by_rank = await techniqueAPIService.getTechniqueByRank(rank.id, this.$router);
          for (let item of techniques_by_rank){
            for (let tech of all_techs){
              if (tech.id === item.technique_id){
                if (y > (pageHeight - 10)){
                  doc.addPage()
                }
                doc.text(tech.description, x + 5, y);
                y += 5;
                break
              }
            }
          }
          if (this.stepSparring.pdf){
            doc.setFontSize('10')
            doc.text('Step Sparring File: ', x + 2, y)
            y += 5
            doc.setFontSize('8')
            doc.text(this.stepSparring.pdf, x + 5, y)
            y += 5
          } else {
            this.error_txt = "Current step sparring doesn't have an assigned file"
            this.openModal('error')
          }

          y += 3;
        } catch (e) {
          this.error_txt = 'Unresolved data error getting techniques to ranks: ';
          this.openModal('error');
        }



        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Self Defense', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let self_defense_by_rank = null;
        try {
          self_defense_by_rank = await selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(rank.id, this.$router);
          for (let item of self_defense_by_rank){
            for (let self_def of all_self_defs){
              if (y > (pageHeight - 10)){
                doc.addPage()
              }
              if (self_def.id === item.self_defense_techniques_id){
                doc.text(self_def.description, x + 5, y);
                y += 5;
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Unresolved data error getting self defense techniques to ranks: ';
          this.openModal('error');
        }

        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Breaking', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let breaking_by_rank = null;
        try {
          breaking_by_rank = await breakingTechniquesAPIService.getBreakingTechniquesByRankID(rank.id, this.$router);
          for (const tech of breaking_by_rank) {
            const techniques = tech.breaking_techniques;
            for (const technique of techniques) {
              if (y > (pageHeight - 10)){
                doc.addPage()
              }
              doc.text(technique.description, x + 5, y);
              y += 5;
            }
          }
        } catch (e) {
          this.error_txt = 'Unresolved data error getting self techniques to ranks: ';
          this.openModal('error');
        }

        x = 10;
        y = 10;

        if (this.givenData.indexOf(order) !== this.givenData.length - 1){
          doc.addPage()
        } else {
          fileName = rank.name + " Curriculum.pdf"
        }
      }
      if (this.givenData.length > 1){
        fileName = this.printName + endOfFileName
      }
      doc.save(fileName);
    },
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = this.filename;
          this.spinning = false;
          link.click();
        })
        .catch((error) => {
          this.error_txt = 'Step Sparring PDF download failed! ' + error;
          this.openModal('error');
          this.spinning = false;
        })
    },
    async printFoundationCerts(type) {
      // get kicks and motions for the kick combos

      let blackBeltID
      for (let rank of this.allRanks){
        if (rank.name === "9th Degree"){
          blackBeltID = rank.id
          break
        }
      }
      const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      const date = new Date();
      // Get the width of the page
      const doc = new JsPDF({
        orientation: "landscape",
        format: 'letter',
      });
      let empty = true
      let count = 0
      function addFontToPDF(pdf) {
        pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
        pdf.addFont('nyala.ttf', 'nyala', 'normal');
        pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
        pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
        pdf.addFileToVFS('nyala.ttf', nyalaBoldBase64);
        pdf.addFont('nyala.ttf', 'nyala', 'bold');
        pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
        pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
      }

      addFontToPDF(doc)

      const CertImg = new Image();
      let VO = this
      let fileName = this.printName

      switch (type){
        case 'red':
          CertImg.src = base64RedCert
          fileName += " Color Belt Certificates"
          break
        case 'prelim':
          CertImg.src = base64PrelimCert
          fileName += " Prelim Certificates"
          break
        case 'foundation':
          CertImg.src = base64FoundationCert
          fileName += " Foundations Certificates"
          break
        case 'black':
          if (this.theme === ''){
            VO.error_txt = "No Theme Entered"
            VO.openModal('error')
            this.loading = false
            return
          }
          CertImg.src = base64BlackCert
          fileName += " Black Belt Certificates"
      }
      CertImg.onload = function () {
        for (let order of VO.givenData) {
          let index = VO.givenData.indexOf(order) + 1
          VO.precentage = (index / VO.givenData.length) * 100
          VO.precentage = parseFloat((Math.round(VO.precentage * 100) / 100).toFixed(1))

          if (VO.checkSelected(order.id)) {
            empty = false
            count++

            const imgWidth = this.width;
            const imgHeight = this.height;

            const aspectRatio = imgWidth / imgHeight;

            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();

            let renderWidth, renderHeight;
            if (aspectRatio > pageWidth / pageHeight) {
              renderWidth = pageWidth;
              renderHeight = pageWidth / aspectRatio;
            } else {
              renderHeight = pageHeight;
              renderWidth = pageHeight * aspectRatio;
            }

            let x = (pageWidth - renderWidth) / 2;
            let y = (pageHeight - renderHeight) / 2;

            if (type === 'black') {
              doc.addImage(CertImg, 'JPEG', x, y, renderWidth, renderHeight);
              doc.setFont('Parchment', 'normal')
              doc.setFontSize(80)
              let userName = order.user.first_name + " " + order.user.last_name
              const xTarget = 155;
              y = 102;
              let textWidth = doc.getTextWidth(userName);
              x = xTarget - (textWidth / 2);
              doc.text(userName, x, y)

              y = 140;
              doc.setFontSize(60)
              textWidth = doc.getTextWidth(order.nextRank.name);
              x = xTarget - (textWidth / 2);
              doc.text(order.nextRank.name, x, y)

              y = 190
              doc.setFontSize(110)
              textWidth = doc.getTextWidth(VO.theme);
              x = (xTarget + 10) - (textWidth / 2);
              doc.text(VO.theme, x, y)

              let monthName = ''
              doc.setFont('lastninja', 'normal')
              printDate(date.getMonth())
              printYear()
              function printDate(month){
                switch (month){
                  case 0:
                    monthName = "January"
                    doc.setFontSize(25)
                    y = 137
                    x = 42.5
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 10
                    }
                    break
                  case 1:
                    monthName = "February"
                    doc.setFontSize(21)
                    y = 135.5
                    x = 43
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 9
                    }
                    break
                  case 2:
                    monthName = "March"
                    doc.setFontSize(34)
                    y = 140
                    x = 41
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 13.5
                    }
                    break
                  case 3:
                    monthName = "April"
                    doc.setFontSize(34)
                    y = 140
                    x = 41
                    for (let letter of monthName){
                      if (letter === 'i') x = 44
                      else x = 41
                      doc.text(letter, x, y)
                      y += 13.5
                    }
                    break
                  case 4:
                    monthName = "May"
                    doc.setFontSize(34)
                    y = 140
                    x = 41
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 27
                    }
                    break
                  case 5:
                    monthName = "June"
                    doc.setFontSize(34)
                    y = 140
                    x = 41
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 18
                    }
                    break
                  case 6:
                    monthName = "July"
                    doc.setFontSize(34)
                    y = 140
                    x = 41
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 18
                    }
                    break
                  case 7:
                    monthName = "August"
                    doc.setFontSize(30)
                    y = 136
                    x = 42
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 12
                    }
                    break
                  case 8:
                    monthName = "September"
                    doc.setFontSize(19)
                    y = 134
                    x = 43.5
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 8
                    }
                    break
                  case 9:
                    monthName = "October"
                    doc.setFontSize(25)
                    y = 137
                    x = 42.5
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 10
                    }
                    break
                  case 10:
                    monthName = "November"
                    doc.setFontSize(21)
                    y = 135.5
                    x = 43
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 9
                    }
                    break
                  case 11:
                    monthName = "December"
                    doc.setFontSize(21)
                    y = 135.5
                    x = 43
                    for (let letter of monthName){
                      doc.text(letter, x, y)
                      y += 9
                    }
                    break
                }
              }
              function printYear(){
                doc.setFontSize(30)
                y = 145
                x = 55
                for (let letter of date.getFullYear().toString()){
                  doc.text(letter, x, y)
                  y += 15
                }
              }

            } else {
              doc.addImage(CertImg, 'JPEG', x, y, renderWidth, renderHeight);
              doc.setFont('nyala', 'normal')
              doc.setFontSize(60)
              let userName = order.user.first_name + " " + order.user.last_name
              let xTarget = pageWidth / 2;
              y = 98;
              let textWidth = doc.getTextWidth(userName);
              x = xTarget - (textWidth / 2);

              doc.setFontSize(60)
              const outlineColor = [255, 255, 255];
              const textColor = [0, 0, 0];
              let outline = .7

              drawOutlinedText(userName, x, y, outlineColor, textColor, outline);
              doc.setFontSize(35)
              let rankName = ''
              if (type === 'prelim') {
                rankName = order.rank.name
              } else {
                rankName = order.nextRank.name
              }
              textWidth = doc.getTextWidth(rankName);
              x = xTarget - (textWidth / 2);
              y = 143
              outline = .5
              drawOutlinedText(rankName, x, y, outlineColor, textColor , outline);

              doc.setFontSize(16)
              let dateString = monthsShort[date.getMonth()] + ' ' + date.getFullYear()
              xTarget = 240
              textWidth = doc.getTextWidth(dateString);
              x = xTarget - (textWidth / 2);
              y = 190
              doc.text(dateString, x, y)

              function drawOutlinedText(text, x, y, outlineColor, textColor, offset) {
                doc.setTextColor(...outlineColor);

                for (let i = offset * -1; i <= offset; i += .1) {
                  doc.text(text, x + i, y + offset)
                  doc.text(text, x + offset, y + i)
                  doc.text(text, x + i, y - offset)
                  doc.text(text, x - offset, y + i)
                }

                doc.setTextColor(...textColor);
                doc.text(text, x, y);
              }

            }

            if (count !== VO.selectedData.length) {
              doc.addPage();
            }
          }
        }
        if (!empty) {
          doc.save(fileName);
        } else {
          VO.error_txt = "No Competitors selected"
          VO.openModal('error')
        }
      }
      CertImg.onerror = function(e) {
        VO.error_txt = type[0].toUpperCase() + type.slice(1) + ' Certificate failed to load: ' + e
        VO.openModal('error')
      }

    },
    async printScores() {
      let scores_objects = {};
      // pull the required items we will be needing from the DB
      const outlines = [];

      for (const data of this.givenData) {
        if (this.selectedData.includes(data.id)){
          if (data.outline){
            await outlineAPIService.getOutlineByID(data.outline.id, this.$router)
              .then((outline) => {
                let temp = {
                  outline: outline,
                  rank: data.rank,
                }
                outlines.push(temp);
              })
              .catch((error) => {
                this.error_txt = 'Lock failed: ' + error;
                this.openModal('error');
              });
          } else {
            this.error_txt = 'One or more of the selected competitors is missing an outline'
            this.openModal('error')
            this.loading = false
            return
          }
        }
      }
      return Promise.all(outlines)
        .then(() => {
          return outlineAPIService.getBase64File('score_view_items.png', this.$router)
        })
        .then((response) => {
          const doc = new JsPDF({
            orientation: "landscape",
            format: 'letter',
          });
          const imgData = response;
          let count = 0

          for (const data of outlines) {
            let index = outlines.indexOf(data) + 1
            this.precentage = (index / outlines.length) * 100
            this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

            let outline = data.outline
            count++
            scores_objects = {};
            this.setTechniqueNames(outline);
            // get all the scores into a nice bundle so we can print it
            scores_objects = this.calculateScoresForOutline(outline);

            let x = 5;
            let y = 10;
            doc.setFontSize('12');
            doc.text(outline.user.first_name + ' ' + outline.user.last_name + '-' + outline.weight + ' lbs,  Weight Class ' + scores_objects.userClass, x, y);
            x = 118;
            doc.setFontSize('20');
            doc.text(outline.description, x, y);
            doc.setFontSize('12');
            y += 5;
            x = 10;
            doc.text('Required Number of Breaks: ' + data.rank.number_of_breaks, x, y);
            x += 70;
            doc.text('Required Speed Breaks: ' + data.rank.required_speed_breaks, x, y);
            x += 60;
            doc.text('Required Power Breaks: ' + data.rank.required_power_breaks, x, y);
            x += 60;
            doc.text('Breaking Point Minimum: ' + data.rank.breaking_point_minimum, x, y);
            x = 10;
            y += 5;

            // now display the requirements met objects
            if (outline.required_number_breaks) {
              doc.setTextColor(0, 255, 0);
              doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
            } else {
              doc.setTextColor(255, 0, 0);
              doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
            }
            x += 70;

            if (outline.required_speed_breaks) {
              doc.setTextColor(0, 255, 0);
              doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
            } else {
              doc.setTextColor(255, 0, 0);
              doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
            }
            x += 60;

            if (outline.required_power_breaks) {
              doc.setTextColor(0, 255, 0);
              doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
            } else {
              doc.setTextColor(255, 0, 0);
              doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
            }
            x += 60;

            if (outline.required_minimum_score) {
              doc.setTextColor(0, 255, 0);
              doc.text('Have: ' + scores_objects.total_average_score, x, y);
            } else {
              doc.setTextColor(255, 0, 0);
              doc.text('Have: ' + scores_objects.total_average_score, x, y);
            }

            doc.setTextColor(0, 0, 0);
            doc.setFontSize('8');

            x = 10;
            y += 30;

            x = 10;
            y = 35;

            doc.setFontSize('8');
            // set each technique column up, one by one
            doc.text('Order', x, y);
            x += 17;
            doc.text('Technique', x, y);
            x += 85;
            doc.text('Board Size', x, y);
            x += 23;
            doc.text('# of Boards', x, y);
            x += 23;
            doc.text('Attempts', x, y);
            x += 15;
            doc.addImage(imgData, 'PNG', x, 22, 50, 20);
            x += 50;
            let tmp_x = x;
            let tmp_y = y + 3;
            doc.text('Possible', x, y);
            doc.text('Score', tmp_x, tmp_y);
            x += 15;
            doc.text('Penalties', x, y);
            x += 15;
            doc.text('Technical', x, y);
            tmp_x = x;
            tmp_y = y + 3;
            doc.text('Score', tmp_x, tmp_y);
            x += 17;
            // doc.text('Final Score', x, y);
            // x += 17;

            for (const technique of outline.breaking_techniques_to_outlines) {
              const board_id = technique.board_id;
              x = 10;
              y += 10;
              doc.text(technique.order.toString(), x, y);
              x += 17;
              doc.text(technique.techName.toString(), x, y);
              x += 85;
              for (const board of this.dropdown_boards) {
                if (board.id === board_id) {
                  doc.text(board.name, x, y);
                  break;
                }
              }
              x += 23;

              doc.text(technique.num_boards.toString(), x, y);
              x += 24;
              doc.text(technique.attempts.toString(), x, y);
              x += 17;
              doc.rect(x, y - 3, 5, 5);
              if (technique.changed_break) {
                doc.text('X', x + 2, y + 1);
              }
              x += 13;
              doc.rect(x, y - 3, 5, 5);
              if (technique.dropped_a_board) {
                doc.text('X', x + 2, y + 1);
              }
              x += 13;
              doc.rect(x, y - 3, 5, 5);
              if (technique.partial_break) {
                doc.text('X', x + 2, y + 1);
              }
              x += 13;
              doc.rect(x, y - 3, 5, 5);
              if (technique.didnt_break) {
                doc.text('X', x + 2, y + 1);
              }
              x += 10;

              doc.text(scores_objects[technique.id].line_score.toString(), x, y);
              x += 15;
              doc.text(scores_objects[technique.id].penalties.toString(), x, y);
              x += 15;
              doc.text(scores_objects[technique.id].total_score.toString(), x, y);
              //x += 17;
              // doc.text(this.scores_objects[technique.id].total_score.toString(), x, y);
            }

            // total boards
            y += 7;
            x = 10 + 17 + 75 + 23;
            doc.text("Total Boards: " + scores_objects.total_boards.toString(), x, y);
            x += 24 + 10 + 10 + 12 + 12 + 11;
            doc.text('Possible Score: ' + scores_objects.total_average_score.toString(), x, y);
            x += 12 + 15 + 8;
            doc.text('Tech Score: ' + scores_objects.final_technical_score.toString(), x, y);

            y += 10;
            x = 40;

            // second part, totals
            doc.text('J1', x, y);
            x += 15;
            doc.text('J2', x, y);
            x += 15;
            doc.text('J3', x, y);
            x += 15;
            doc.text('J4', x, y);
            x += 15;
            doc.text('J5', x, y);
            x += 15;
            doc.text('Time', x, y);
            x += 15;
            doc.text('Presentation', x, y);
            x += 45;
            doc.text('Technical Score', x, y);
            x += 25;
            doc.text('Final Score', x, y);

            y += 10;
            x = 40;

            if (!outline.j1) {
              outline.j1 = 0;
            }
            if (!outline.j2) {
              outline.j2 = 0;
            }
            if (!outline.j3) {
              outline.j3 = 0;
            }
            if (!outline.j4) {
              outline.j4 = 0;
            }
            if (!outline.j5) {
              outline.j5 = 0;
            }
            doc.text(outline.j1.toString(), x, y);
            x += 15;
            doc.text(outline.j2.toString(), x, y);
            x += 15;
            doc.text(outline.j3.toString(), x, y);
            x += 15;
            doc.text(outline.j4.toString(), x, y);
            x += 15;
            doc.text(outline.j5.toString(), x, y);
            x += 15;
            if (!outline.time) {
              outline.time = 0;
            }
            doc.text(outline.time.toString(), x, y);
            x += 15;
            if (!scores_objects.presentation) {
              scores_objects.presentation = 0;
            }
            doc.text(scores_objects.presentation.toFixed(2).toString(), x, y);
            x += 45;
            if (!scores_objects.total_score) {
              scores_objects.total_score = 0;
            }
            scores_objects.final_technical_score = parseInt(scores_objects.final_technical_score);
            doc.text(scores_objects.final_technical_score.toString(), x, y);
            x += 25;
            if (!scores_objects.total_score) {
              scores_objects.total_score = 0;
            }
            if (scores_objects.total_average_score) {
              scores_objects.total_average_score = parseFloat(scores_objects.total_average_score);
              scores_objects.total_average_score = scores_objects.total_average_score.toFixed(2);
            } else {
              scores_objects.total_average_score = 0;
            }
            doc.text(scores_objects.total_average_score.toString(), x, y);

            // now put the totals in at the bottom
            y += 10;
            x = 10;

            const boards = {};
            doc.text('Total Board Breakdown: ', x, y);
            for (const board of this.dropdown_boards) {
              boards[board.id] = {
                name: board.name,
                total: 0,
              };
            }
            y += 6;
            for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
              // first get the number of the board, add all them together
              boards[technique_to_outline.board_id].total += parseInt(technique_to_outline.num_boards);
            }
            const keys = Object.keys(boards);
            for (const key of keys) {
              const board = boards[key];
              if (board.name !== 'N/A') {
                x = 10;
                doc.text(board.name + 's - ' + board.total.toString(), x, y);
                y += 6;
              }
            }
            x += 24;

            if (count !== this.selectedData.length){
              doc.addPage();
            }
          }
          let fileName = this.printName + ' Score Sheet.pdf'
          doc.save(fileName);
        })
        .catch((error) => {
          this.loading = false;
          this.error_txt = 'Error adding row: ' + error;
          this.openModal('error');
        });
    },
    async printOutlines() {
      let scores_objects = {};
      // pull the required items we will be needing from the DB
      const outlines = [];

      for (const data of this.givenData) {
        if (this.selectedData.includes(data.id)) {
          if (data.outline){
            await outlineAPIService.getOutline(data.outline.id, this.$router)
              .then((outline) => {
                let temp = {
                  outline: outline,
                  rank: data.rank,
                }
                outlines.push(temp);
              })
              .catch((error) => {
                this.error_txt = 'Lock failed: ' + error;
                this.openModal('error');
              });
          } else {
            this.error_txt = 'One or more of the selected competitors is missing an outline'
            this.openModal('error')
            this.loading = false
            return
          }
        }
      }
      return Promise.all(outlines)
        .then(() => {
          return outlineAPIService.getBase64File('pdf_titles.png', this.$router)
        })
        .then((response) => {
          const doc = new JsPDF({
            orientation: "landscape",
            format: 'letter',
          });
          doc.setTextColor(0, 0, 0);
          const imgData = response;
          let count = 0

          for (const data of outlines) {
            let index = outlines.indexOf(data) + 1
            this.precentage = (index / outlines.length) * 100
            this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

            count++
            let outline = data.outline
            scores_objects = {};
            this.setTechniqueNames(outline);
            // get all the scores into a nice bundle so we can print it
            scores_objects = this.calculateScoresForOutline(outline);

            // set the description in the center
            let x = 118;
            let y = 10;
            doc.text(outline.description, x, y);
            x = 10;
            y = 10;

            doc.setFontSize('12');
            doc.text(outline.user.first_name + ' ' + outline.user.last_name + '-' + outline.weight + ' lbs,  Weight Class ' + scores_objects.userClass, x, y);

            y += 5;
            x = 10;
            doc.text('Required Number of Breaks: ' + data.rank.number_of_breaks, x, y);
            x += 70;
            doc.text('Required Speed Breaks: ' + data.rank.required_speed_breaks, x, y);
            x += 60;
            doc.text('Required Power Breaks: ' + data.rank.required_power_breaks, x, y);
            x += 60;
            doc.text('Breaking Point Minimum: ' + data.rank.breaking_point_minimum, x, y);
            x = 10;
            y += 5;

            // now display the requirements met objects
            if (outline.required_number_breaks) {
              doc.setTextColor(0, 255, 0);
              doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
            } else {
              doc.setTextColor(255, 0, 0);
              doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
            }
            x += 70;

            if (outline.required_speed_breaks) {
              doc.setTextColor(0, 255, 0);
              doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
            } else {
              doc.setTextColor(255, 0, 0);
              doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
            }
            x += 60;

            if (outline.required_power_breaks) {
              doc.setTextColor(0, 255, 0);
              doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
            } else {
              doc.setTextColor(255, 0, 0);
              doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
            }
            x += 60;

            if (outline.required_minimum_score) {
              doc.setTextColor(0, 255, 0);
              doc.text('Have: ' + scores_objects.total_average_score, x, y);
            } else {
              doc.setTextColor(255, 0, 0);
              doc.text('Have: ' + scores_objects.total_average_score, x, y);
            }

            doc.setTextColor(0, 0, 0);
            doc.setFontSize('8');
            // set each technique column up, one by one
            x = 10;
            y = 55;
            doc.text('Order', x, y);
            x += 17;
            doc.text('Technique', x, y);
            x += 90;
            doc.text('Board Size', x, y);
            x += 23;
            doc.text('# of Boards', x, y);
            x += 23;
            y = 30;
            doc.addImage(imgData, 'PNG', x, y, 60, 30);
            y = 55;
            x += 66;
            doc.text('Score', x, y);

            for (const technique of outline.breaking_techniques_to_outlines) {
              const techniqueDirections = technique.breaking_technique.directions;
              const direction_id = technique.direction_id;
              const board_id = technique.board_id;
              const modifier_id = technique.dropdown_modifier_id;
              x = 10;
              y += 10;
              doc.text(technique.order.toString(), x, y);
              x += 17;
              let technique_string = '';
              for (const modifier of this.dropdown_support_options) {
                if (modifier.id === modifier_id) {
                  technique_string += modifier.description + " ";
                  break;
                }
              }
              technique_string += technique.breaking_technique.name + " ";

              for (const dir of techniqueDirections) {
                if (dir.id === direction_id) {
                  if (dir.id !== null && dir.name !== "N/A") {
                    technique_string += dir.name + " ";
                  }
                  break;
                }
              }
              doc.text(technique_string, x, y);
              x += 90;

              for (const board of this.dropdown_boards) {
                if (board.id === board_id) {
                  doc.text(board.name, x, y);
                  break;
                }
              }
              x += 23;

              doc.text(technique.num_boards.toString(), x, y);
              x += 24;

              doc.rect(x, y - 3, 5, 5);
              if (technique.self_held) {
                doc.text('X', x + 2, y + 1);
              }
              x += 10;

              doc.rect(x, y - 3, 5, 5);
              if (technique.blindfolded) {
                doc.text('X', x + 2, y + 1);
              }
              x += 10;

              doc.rect(x, y - 3, 5, 5);
              if (technique.simultaneous) {
                doc.text('X', x + 2, y + 1);
              }
              x += 12;

              doc.rect(x, y - 3, 5, 5);
              if (technique.successive) {
                doc.text('X', x + 2, y + 1);
              }
              x += 12;

              doc.rect(x, y - 3, 5, 5);
              if (technique.no_spacers) {
                doc.text('X', x + 2, y + 1);
              }
              x += 23;

              // print the score out
              doc.text(scores_objects[technique.id].line_score.toString(), x, y);
            }

            // now put the totals in at the bottom
            // start with total boards
            y += 10;
            x = 10 + 17 + 75 + 23;
            doc.text("Total Boards: " + scores_objects.total_boards.toString(), x, y);
            x += 24 + 10 + 10 + 12 + 12 + 11 + 12;
            doc.text('Possible Score: ' + scores_objects.total_average_score.toString(), x, y);

            y += 10;
            x = 10;

            const boards = {};
            doc.text('Total Board Breakdown: ', x, y);
            for (const board of this.dropdown_boards) {
              boards[board.id] = {
                name: board.name,
                total: 0,
              };
            }
            y += 6;
            for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
              // first get the number of the board, add all them together
              boards[technique_to_outline.board_id].total += parseInt(technique_to_outline.num_boards);
            }
            const keys = Object.keys(boards);
            for (const key of keys) {
              const board = boards[key];
              if (board.name !== 'N/A') {
                x = 10;
                doc.text(board.name + 's - ' + board.total.toString(), x, y);
                y += 6;
              }
            }
            x += 24;

            // add 1 page per user, just like it shows right now
            if (count !== this.selectedData.length){
              doc.addPage();
            }
          }
          let fileName = this.printName + ' Outlines Sheet.pdf'
          doc.save(fileName);
        })
        .catch((error) => {
          this.loading = false;
          this.error_txt = 'Error: ' + error;
          this.openModal('error');
        });
    },
    async printRollSheets(){
      const doc = new JsPDF({
        orientation: "portrait",
        format: 'letter',
      });
      let fileName = this.printName
      let empty = true
      let count = 0

      function addFontToPDF(pdf) {
        pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
        pdf.addFont('nyala.ttf', 'nyala', 'normal');
        pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
        pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
        pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
        pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
        pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
        pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
      }

      addFontToPDF(doc)

      doc.setFontSize('10');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      let x = 10;
      let y = 10;

      function printTopInfo(printName, programName){
        let string = printName + ' - ' + programName + ' - Roll Sheet'
        doc.setFontSize('16')
        y = 10
        x = 10
        doc.setFont('times', 'bold')
        let textWidth = doc.getTextWidth(string)
        doc.text(string, halfPage - (textWidth / 2), y);
        doc.setFont('times', 'normal')
        doc.setFontSize('10')
        y += 8;
        doc.text('First Name', x + 40, y)
        doc.text('Last Name', x + 70, y)
        doc.text('Gender', x + 100, y)
        doc.text('Age', x + 125, y)
        doc.text('Current Rank', x + 140, y)
        y += 10
      }

      if (this.printByTypes[this.printType].name === 'Program'){
        fileName += ' Programs '
        for (let id of this.selectedData){
          let index = this.selectedData.indexOf(id) + 1
          this.precentage = (index / this.selectedData.length) * 100
          this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

          empty = false
          count++

          let programName = ''
          for (let item of this.filteredData){
            if (item.id === id){
              programName = item.name
              break
            }
          }
          printTopInfo(this.printName, programName)


          let dataArray = this.givenData.filter(e => e.program.id === id)
          dataArray.sort((a, b) => a.rank.order - b.rank.order);

          let programPageCount = 1
          let totalPageCount = Math.ceil(dataArray.length / 35)
          let newPage = true
          for (let order of dataArray){
            if (y > (pageHeight - 10)){
              programPageCount++
              doc.addPage()
              printTopInfo(this.printName, programName)
              newPage = true
            }
            if (newPage){
              doc.setFontSize('8')
              doc.text(programName + ' Page ' + programPageCount + '/' + totalPageCount, 10, 10)
              newPage = false
            }
            doc.setFontSize('12')
            let rectSize = 5
            doc.rect(x + 30, y - 4, rectSize, rectSize)
            doc.text(order.user.first_name, x + 40, y)
            doc.text(order.user.last_name, x + 70, y)
            if (order.user.gender){
              doc.text(order.user.gender, x + 100, y)
            } else {
              doc.text('Unknown', x + 100, y)
            }
            doc.text(JSON.stringify(order.user.age), x + 125, y)
            doc.text(order.rank.name, x + 140, y)
            y+=7
          }

          if (count !== this.selectedData.length){
            doc.addPage();
          }
        }
      }
      else if (this.printByTypes[this.printType].name === 'Rank'){
        fileName += ' Ranks '
        for (let id of this.selectedData){
          let index = this.selectedData.indexOf(id) + 1
          this.precentage = (index / this.selectedData.length) * 100
          this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

          empty = false
          count++

          let rankName = ''
          for (let item of this.filteredData){
            if (item.id === id){
              rankName = item.name
              break
            }
          }
          printTopInfo(this.printName, rankName)


          let dataArray = this.givenData.filter(e => e.rank.id === id)
          dataArray.sort((a, b) => a.rank.order - b.rank.order);

          let rankPageCount = 1
          let totalPageCount = Math.ceil(dataArray.length / 35)
          let newPage = true
          for (let order of dataArray){
            if (y > (pageHeight - 10)){
              rankPageCount++
              doc.addPage()
              printTopInfo(this.printName, rankName)
              newPage = true
            }
            if (newPage){
              doc.setFontSize('8')
              doc.text(rankName + ' Page ' + rankPageCount + '/' + totalPageCount, 10, 10)
              newPage = false
            }
            doc.setFontSize('12')
            let rectSize = 5
            doc.rect(x + 30, y - 4, rectSize, rectSize)
            doc.text(order.user.first_name, x + 40, y)
            doc.text(order.user.last_name, x + 70, y)
            if (order.user.gender){
              doc.text(order.user.gender, x + 100, y)
            } else {
              doc.text('Unknown', x + 100, y)
            }
            doc.text(JSON.stringify(order.user.age), x + 125, y)
            doc.text(order.rank.name, x + 140, y)
            y+=7
          }

          if (count !== this.selectedData.length){
            doc.addPage();
          }
        }
      }
      else if (this.printByTypes[this.printType].name === 'Groups'){
        fileName += ' Groups '
        for (let id of this.selectedData){
          let index = this.selectedData.indexOf(id) + 1
          this.precentage = (index / this.selectedData.length) * 100
          this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

          empty = false
          count++

          let typeName = ''
          for (let item of this.filteredData){
            if (item.id === id){
              typeName = item.name
              break
            }
          }
          printTopInfo(this.printName, typeName)


          let dataArray = this.givenData.filter(e => e.testing_type_id === id)
          dataArray.sort((a, b) => a.rank.order - b.rank.order);

          let typePageCount = 1
          let totalPageCount = Math.ceil(dataArray.length / 35)
          let newPage = true
          for (let i = 1; i <= 5; i++){
            if (i !== 1){
              y += 10
            }
            doc.setFontSize('12')
            doc.text('Group ' + i, 10, y)
            for (let order of dataArray){
              if (order.group.group_id === i) {
                if (y > (pageHeight - 10)){
                  typePageCount++
                  doc.addPage()
                  printTopInfo(this.printName, typeName)
                  newPage = true
                }
                if (newPage){
                  doc.setFontSize('8')
                  doc.text(typeName + ' Page ' + typePageCount + '/' + totalPageCount, 10, 10)
                  newPage = false
                }
                doc.setFontSize('12')
                let rectSize = 5
                doc.rect(x + 30, y - 4, rectSize, rectSize)
                doc.text(order.user.first_name, x + 40, y)
                doc.text(order.user.last_name, x + 70, y)
                if (order.user.gender){
                  doc.text(order.user.gender, x + 100, y)
                } else {
                  doc.text('Unknown', x + 100, y)
                }
                doc.text(JSON.stringify(order.user.age), x + 125, y)
                doc.text(order.rank.name, x + 140, y)
                y+=7
              }
            }
          }

          if (count !== this.selectedData.length){
            doc.addPage();
          }
        }
      }
      else if (this.printByTypes[this.printType].name === 'Rank Level'){
        fileName += ' Rank Levels '
        for (let id of this.selectedData){
          let index = this.selectedData.indexOf(id) + 1
          this.precentage = (index / this.selectedData.length) * 100
          this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

          empty = false
          count++

          let typeName = ''
          for (let item of this.filteredData){
            if (item.id === id){
              typeName = item.name
              break
            }
          }
          printTopInfo(this.printName, typeName)


          let dataArray = this.givenData.filter(e => e.rank.rank_type_id === id)
          dataArray.sort((a, b) => a.rank.order - b.rank.order);

          let typePageCount = 1
          let totalPageCount = Math.ceil(dataArray.length / 35)
          let newPage = true
          for (let order of dataArray){
            if (y > (pageHeight - 10)){
              typePageCount++
              doc.addPage()
              printTopInfo(this.printName, typeName)
              newPage = true
            }
            if (newPage){
              doc.setFontSize('8')
              doc.text(typeName + ' Page ' + typePageCount + '/' + totalPageCount, 10, 10)
              newPage = false
            }
            doc.setFontSize('12')
            let rectSize = 5
            doc.rect(x + 30, y - 4, rectSize, rectSize)
            doc.text(order.user.first_name, x + 40, y)
            doc.text(order.user.last_name, x + 70, y)
            if (order.user.gender){
              doc.text(order.user.gender, x + 100, y)
            } else {
              doc.text('Unknown', x + 100, y)
            }
            doc.text(JSON.stringify(order.user.age), x + 125, y)
            doc.text(order.rank.name, x + 140, y)
            y+=7
          }

          if (count !== this.selectedData.length){
            doc.addPage();
          }
        }
      }

      if (!empty) {
         fileName += " Roll Sheets.pdf"
        doc.save(fileName);
      } else {
        this.error_txt = "No Competitors selected"
        this.openModal('error')
      }
    },
    async printBBTRollSheets(){
      const doc = new JsPDF({
        orientation: "portrait",
        format: 'letter',
      });
      let fileName = this.printName
      let empty = true
      let count = 0

      function addFontToPDF(pdf) {
        pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
        pdf.addFont('nyala.ttf', 'nyala', 'normal');
        pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
        pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
        pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
        pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
        pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
        pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
      }

      addFontToPDF(doc)

      doc.setFontSize('10');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      let x = 10;
      let y = 10;

      function printTopInfo(printName, programName){
        let string = printName + ' - ' + programName + ' - Roll Sheet'
        doc.setFontSize('16')
        y = 10
        x = 10
        doc.setFont('times', 'bold')
        let textWidth = doc.getTextWidth(string)
        doc.text(string, halfPage - (textWidth / 2), y);
        doc.setFont('times', 'normal')
        doc.setFontSize('10')
        y += 8;
        doc.text('First Name', x + 40, y)
        doc.text('Last Name', x + 70, y)
        doc.text('Gender', x + 100, y)
        doc.text('Age', x + 125, y)
        doc.text('Current Rank', x + 140, y)
        y += 10
      }

      const columns = ["ID", "Name", "Age", "Country"];
      const rows = [
        [1, "John Doe", 28, "USA"],
        [2, "Anna Smith", 24, "UK"],
        [3, "Peter Jones", 35, "Canada"],
      ];

      empty = false;

      doc.text("Sample Table", 20, 10);

      doc.autoTable({
        head: [columns],
        body: rows,
        styles: {
          lineColor: [0, 0, 0],  // Black borders
          lineWidth: 0.5,        // Slightly thicker border
          fontSize: 10,          // Default font size for table
          font: 'helvetica',     // Helvetica font for the entire table
        },
        headStyles: {
          fillColor: [0, 102, 204],  // Blue background for header
          textColor: [255, 255, 255],// White text in header
          fontSize: 12,              // Larger font for headers
          fontStyle: 'bold',         // Bold text for headers
        },
        bodyStyles: {
          fillColor: [245, 245, 245], // Light gray background for body rows
          textColor: [0, 0, 0],       // Black text in body rows
          lineColor: [200, 200, 200], // Light gray borders for body rows
        },
        alternateRowStyles: {
          fillColor: [255, 255, 255], // White background for alternating rows
        },
        margin: { top: 20 },
      });



      /*if (this.printByTypes[this.printType].name === 'Program'){
        fileName += ' Programs '
        for (let id of this.selectedData){
          let index = this.selectedData.indexOf(id) + 1
          this.precentage = (index / this.selectedData.length) * 100
          this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

          empty = false
          count++

          let programName = ''
          for (let item of this.filteredData){
            if (item.id === id){
              programName = item.name
              break
            }
          }
          printTopInfo(this.printName, programName)


          let dataArray = this.givenData.filter(e => e.program.id === id)
          dataArray.sort((a, b) => a.rank.order - b.rank.order);

          let programPageCount = 1
          let totalPageCount = Math.ceil(dataArray.length / 35)
          let newPage = true
          for (let order of dataArray){
            if (y > (pageHeight - 10)){
              programPageCount++
              doc.addPage()
              printTopInfo(this.printName, programName)
              newPage = true
            }
            if (newPage){
              doc.setFontSize('8')
              doc.text(programName + ' Page ' + programPageCount + '/' + totalPageCount, 10, 10)
              newPage = false
            }
            doc.setFontSize('12')
            let rectSize = 5
            doc.rect(x + 30, y - 4, rectSize, rectSize)
            doc.text(order.user.first_name, x + 40, y)
            doc.text(order.user.last_name, x + 70, y)
            if (order.user.gender){
              doc.text(order.user.gender, x + 100, y)
            } else {
              doc.text('Unknown', x + 100, y)
            }
            doc.text(JSON.stringify(order.user.age), x + 125, y)
            doc.text(order.rank.name, x + 140, y)
            y+=7
          }

          if (count !== this.selectedData.length){
            doc.addPage();
          }
        }
      }
      else if (this.printByTypes[this.printType].name === 'Rank'){
        fileName += ' Ranks '
        for (let id of this.selectedData){
          let index = this.selectedData.indexOf(id) + 1
          this.precentage = (index / this.selectedData.length) * 100
          this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

          empty = false
          count++

          let rankName = ''
          for (let item of this.filteredData){
            if (item.id === id){
              rankName = item.name
              break
            }
          }
          printTopInfo(this.printName, rankName)


          let dataArray = this.givenData.filter(e => e.rank.id === id)
          dataArray.sort((a, b) => a.rank.order - b.rank.order);

          let rankPageCount = 1
          let totalPageCount = Math.ceil(dataArray.length / 35)
          let newPage = true
          for (let order of dataArray){
            if (y > (pageHeight - 10)){
              rankPageCount++
              doc.addPage()
              printTopInfo(this.printName, rankName)
              newPage = true
            }
            if (newPage){
              doc.setFontSize('8')
              doc.text(rankName + ' Page ' + rankPageCount + '/' + totalPageCount, 10, 10)
              newPage = false
            }
            doc.setFontSize('12')
            let rectSize = 5
            doc.rect(x + 30, y - 4, rectSize, rectSize)
            doc.text(order.user.first_name, x + 40, y)
            doc.text(order.user.last_name, x + 70, y)
            if (order.user.gender){
              doc.text(order.user.gender, x + 100, y)
            } else {
              doc.text('Unknown', x + 100, y)
            }
            doc.text(JSON.stringify(order.user.age), x + 125, y)
            doc.text(order.rank.name, x + 140, y)
            y+=7
          }

          if (count !== this.selectedData.length){
            doc.addPage();
          }
        }
      }
      else if (this.printByTypes[this.printType].name === 'Groups'){
        fileName += ' Groups '
        for (let id of this.selectedData){
          let index = this.selectedData.indexOf(id) + 1
          this.precentage = (index / this.selectedData.length) * 100
          this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

          empty = false
          count++

          let typeName = ''
          for (let item of this.filteredData){
            if (item.id === id){
              typeName = item.name
              break
            }
          }
          printTopInfo(this.printName, typeName)


          let dataArray = this.givenData.filter(e => e.testing_type_id === id)
          dataArray.sort((a, b) => a.rank.order - b.rank.order);

          let typePageCount = 1
          let totalPageCount = Math.ceil(dataArray.length / 35)
          let newPage = true
          for (let i = 1; i <= 5; i++){
            if (i !== 1){
              y += 10
            }
            doc.setFontSize('12')
            doc.text('Group ' + i, 10, y)
            for (let order of dataArray){
              if (order.group.group_id === i) {
                if (y > (pageHeight - 10)){
                  typePageCount++
                  doc.addPage()
                  printTopInfo(this.printName, typeName)
                  newPage = true
                }
                if (newPage){
                  doc.setFontSize('8')
                  doc.text(typeName + ' Page ' + typePageCount + '/' + totalPageCount, 10, 10)
                  newPage = false
                }
                doc.setFontSize('12')
                let rectSize = 5
                doc.rect(x + 30, y - 4, rectSize, rectSize)
                doc.text(order.user.first_name, x + 40, y)
                doc.text(order.user.last_name, x + 70, y)
                if (order.user.gender){
                  doc.text(order.user.gender, x + 100, y)
                } else {
                  doc.text('Unknown', x + 100, y)
                }
                doc.text(JSON.stringify(order.user.age), x + 125, y)
                doc.text(order.rank.name, x + 140, y)
                y+=7
              }
            }
          }

          if (count !== this.selectedData.length){
            doc.addPage();
          }
        }
      }
      else if (this.printByTypes[this.printType].name === 'Rank Level'){
        fileName += ' Rank Levels '
        for (let id of this.selectedData){
          let index = this.selectedData.indexOf(id) + 1
          this.precentage = (index / this.selectedData.length) * 100
          this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

          empty = false
          count++

          let typeName = ''
          for (let item of this.filteredData){
            if (item.id === id){
              typeName = item.name
              break
            }
          }
          printTopInfo(this.printName, typeName)


          let dataArray = this.givenData.filter(e => e.rank.rank_type_id === id)
          dataArray.sort((a, b) => a.rank.order - b.rank.order);

          let typePageCount = 1
          let totalPageCount = Math.ceil(dataArray.length / 35)
          let newPage = true
          for (let order of dataArray){
            if (y > (pageHeight - 10)){
              typePageCount++
              doc.addPage()
              printTopInfo(this.printName, typeName)
              newPage = true
            }
            if (newPage){
              doc.setFontSize('8')
              doc.text(typeName + ' Page ' + typePageCount + '/' + totalPageCount, 10, 10)
              newPage = false
            }
            doc.setFontSize('12')
            let rectSize = 5
            doc.rect(x + 30, y - 4, rectSize, rectSize)
            doc.text(order.user.first_name, x + 40, y)
            doc.text(order.user.last_name, x + 70, y)
            if (order.user.gender){
              doc.text(order.user.gender, x + 100, y)
            } else {
              doc.text('Unknown', x + 100, y)
            }
            doc.text(JSON.stringify(order.user.age), x + 125, y)
            doc.text(order.rank.name, x + 140, y)
            y+=7
          }

          if (count !== this.selectedData.length){
            doc.addPage();
          }
        }
      }*/

      if (!empty) {
         fileName += " Roll Sheets.pdf"
        doc.save(fileName);
      } else {
        this.error_txt = "No Competitors selected"
        this.openModal('error')
      }
    },
    async printCallSheets(){
      const doc = new JsPDF({
        orientation: "portrait",
        format: 'letter',
      });
      let empty = true
      let count = 0

      function addFontToPDF(pdf) {
        pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
        pdf.addFont('nyala.ttf', 'nyala', 'normal');
        pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
        pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
        pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
        pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
        pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
        pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
        pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
      }

      addFontToPDF(doc)

      let left = true

      for (let id of this.selectedData){
        let index = this.selectedData.indexOf(id) + 1
        this.precentage = (index / this.selectedData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        let currentRank
        for (let rank of this.allRanks){
          if (rank.id === id){
            currentRank = rank
            break
          }
        }
        empty = false
        count++
        doc.setFontSize('10');
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const halfPage = pageWidth / 2;
        let x;
        let y = 10;
        if (left){
          x = 10
        } else {
          x = halfPage + 5
        }

        function printTopInfo(rank){
          let textWidth
          let today = new Date()
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          y = 10
          doc.setFont('nyala', 'normal')
          doc.setFontSize('12')
          doc.text(today.toLocaleString('en-US', options), x, y)

          y += 10
          doc.setFont('times', 'normal')
          doc.setFontSize('14');
          let titleString = 'Beyond Sports Taekwondo Curriculum'
          textWidth = doc.getTextWidth(titleString)
          let quartarPage = x + ((halfPage - 10) / 2)
          doc.text(titleString, quartarPage - (textWidth / 2), y);

          y += 10
          textWidth = doc.getTextWidth(rank.name)
          doc.setFont('Times-Roman', 'bolditalic')
          doc.text(rank.name, quartarPage - (textWidth / 2), y)

          y += 10;
        }

        printTopInfo(currentRank)

        for (let category of this.categories) {
          switch (category.id) {
            case "poomsae":
              await this.getPoomsaeByRankId(currentRank.id)
              break
            case "kicks":
              await this.getKicksByRankId(currentRank.id)
              break
            case "self_defense":
              await this.getSelfDefByRankId(currentRank.id)
              break
            case "kick_combos":
              await this.getKickCombosByRankId(currentRank.id)
              break
            case "techniques":
              await this.getTechsByRankId(currentRank.id)
              break
            default:
              continue
          }
          if (category.items.length > 0) {
            for (let item of category.items) {
              if (category.items.indexOf(item) === 0){
                x = 10
                doc.setFont('Times-Roman', 'bolditalic')
                doc.setFontSize('12')
                doc.text(category.name, x, y)
                doc.line(x, y + 1, halfPage, y + 1);
                y += 7
              }
              x = 10
              doc.setFontSize('12')
              doc.setFont('nyala', 'normal')
              doc.text(item.name, x + 5, y)
              y += 7
            }
          }
        }

        if (count !== this.selectedData.length){
          if (!left) {
            doc.addPage();
          }
          left = !left
        }
      }

      if (!empty) {
        let fileName = this.printName + " Call Sheets.pdf"
        doc.save(fileName);
      } else {
        this.error_txt = "No Ranks to Print"
        this.openModal('error')
      }
    },
    setTechniqueNames(outline) {
      if (outline && outline.breaking_techniques_to_outlines) {
        for (const techItem of outline.breaking_techniques_to_outlines) {
          techItem.techName = '';
          for (const modifier of this.dropdown_support_options) {
            if (modifier.id === techItem.dropdown_modifier_id) {
              techItem.techName += modifier.description + ' ';
            }
          }
          if (techItem.breaking_technique && techItem.breaking_technique.name) {
            techItem.techName += techItem.breaking_technique.name + ', ';
          }
          if (techItem.breaking_technique && techItem.breaking_technique.directions) {
            for (const techdir of techItem.breaking_technique.directions) {
              if (techdir && techdir.id !== null && techdir.id === techItem.direction_id) {
                if (techdir.name !== 'N/A') {
                  techItem.techName += techdir.name + ', ';
                }
              }
            }
          }
          if (techItem.blindfolded) {
            techItem.techName += 'Blindfolded, ';
          }
          if (techItem.self_held) {
            techItem.techName += 'Self Held, ';
          }
          if (techItem.simultaneous) {
            techItem.techName += 'Simultaneous, ';
          }
          if (techItem.successive) {
            techItem.techName += 'Successive, ';
          }
          if (techItem.no_spacers) {
            techItem.techName += 'No Spacers, ';
          }
          techItem.techName = techItem.techName.replace(/,\s*$/, "");
          if (techItem.additional_boards === 1) {
            techItem.techName += ' 1 Additional Board';
          } else if (techItem.additional_boards > 1) {
            techItem.techName += techItem.additional_boards + ' Additional Boards';
          }
        }
      }
    },
    calculateScoresForOutline(outline) {
      // storage objects to hold values in prior to calculating scores
      const scores_objects = {};
      scores_objects.pre_final_technical_score = 0;
      scores_objects.final_technical_score = 0;
      scores_objects.final_technical_score_length = 0;
      scores_objects.total_line_scores = 0;
      scores_objects.avg_total_line_scores = 0;
      scores_objects.final_score = 0;
      scores_objects.total_boards = 0;
      scores_objects.total_speed_breaks = 0;
      scores_objects.userClass = 6;
      scores_objects.presentation = 0;
      let VO = this
      const userClass = setUserClass(outline.weight, outline.user);
      scores_objects.userClass = userClass;
      const presentation = setPresentation(outline);
      const board_objects = setBoards(outline, scores_objects);
      const board_size_objects = setBoardSizeObjects(outline);
      const support_option_objects = setSupportOptions(outline, scores_objects);
      const technique_objects = setTechniqueSelections(outline);
      const direction_objects = setDirectionSelections(outline);
      const modifier_objects = setModifiers(outline);
      const penalties_objects = setPenalties(outline);

      // first setup a scores obj we'll send back
      const outline_techniques = outline.breaking_techniques_to_outlines
      /*console.log(outline)*/
      for (const technique_to_outline of outline_techniques) {
        // calculate each line of technique outlines
        const id = technique_to_outline.id;
        scores_objects[id] = {};
        scores_objects[id] = {
          a_minus: 0,
          starting_line_score: 0,
          base_point_value: 0,
          average_board_size: 0,
          additional_points: 0,
          line_score: 0,
          penalties: 0,
          total_score: 0,
          pre_final_technical_score: 0,
          final_technical_score: 0,
          attempts: 0,
          presentation: presentation,
        };
        // initializers
        scores_objects[id].average_board_size = userClass - technique_objects[id].a_minus;
        scores_objects[id].starting_line_score = technique_objects[id].base_point_value +
          board_size_objects[id].order - scores_objects[id].average_board_size;

        // first, get all the a-minuses
        // this.scores_objects[id].a_minus = this.userClass - this.board_size_objects[id].order;
        scores_objects[id].a_minus += support_option_objects[id].a_minus;
        // this.scores_objects[id].a_minus += this.technique_objects[id].a_minus;
        scores_objects[id].a_minus += direction_objects[id].a_minus;
        const modifiers = modifier_objects[id];
        for (const modifier of modifiers) {
          if (modifier.a_minus) {
            scores_objects[id].a_minus += modifier.a_minus;
          }
        }

        // Build the possible score calculations now
        // this.scores_objects[id].possible_score = this.technique_objects[id].base_point_value;
        scores_objects[id].additional_points += (board_objects[id].additional_boards * 2);
        scores_objects[id].additional_points += support_option_objects[id].point_value;
        scores_objects[id].additional_points += direction_objects[id].additional_point_value;

        let no_spacers = false;
        for (const modifier of modifiers) {
          if (modifier.name === 'no_spacers') {
            no_spacers = true;
            scores_objects[id].additional_points += (board_objects[id].additional_boards * 1);
          } else {
            if (modifier.point_value) {
              scores_objects[id].additional_points += modifier.point_value;
            }
          }
        }

        let partial_break = false;
        let drop_board = false;
        let no_break = false;
        const penaltyObj = penalties_objects[id];
        for (const penalty of penaltyObj) {
          let attempts = 0;
          if (penalty.name === 'attempts') {
            attempts += penalty.attempts;
            scores_objects[id].penalties += (attempts);
          }
          if (penalty.name === 'changed_break') {
            scores_objects[id].penalties += penalty.point_value;
          }
          if (penalty.name === 'partial_break') {
            partial_break = true;
          }
          if (penalty.name === 'no_break') {
            no_break = true;
          }
          if (penalty.name === 'dropped_board') {
            drop_board = true;
          }
        }

        // now calculate the possible score
        scores_objects[id].line_score = scores_objects[id].starting_line_score +
          scores_objects[id].a_minus + scores_objects[id].additional_points;
        scores_objects.total_line_scores += scores_objects[id].line_score;
        scores_objects.total_average_score = ((scores_objects.total_line_scores
          / outline_techniques.length) || 0).toFixed(2);

        // now calculate the possible score
        scores_objects[id].total_score = scores_objects[id].line_score;

        if (no_break) {
          scores_objects[id].total_score = 0;
          scores_objects[id].penalties += scores_objects[id].line_score;
        }

        if (drop_board) {
          // for each board dropped it will give 2 points of penalty
          if (no_spacers) {
            scores_objects[id].penalties += (parseInt(technique_to_outline.dropped_a_board) * 2);
            scores_objects[id].penalties += parseInt(technique_to_outline.dropped_a_board);
          } else {
            scores_objects[id].penalties += (parseInt(technique_to_outline.dropped_a_board) * 2);
          }
        }

        if (partial_break) {
          scores_objects[id].penalties += scores_objects[id].line_score / 2;
        }

        // now calculate the total scores for each line
        scores_objects[id].total_score = scores_objects[id].line_score - scores_objects[id].penalties;
        if (scores_objects[id].total_score < 0) {
          scores_objects[id].total_score = 0;
        }
        scores_objects.final_technical_score_length += 1;
        scores_objects.pre_final_technical_score += scores_objects[id].total_score;
        scores_objects.presentation = scores_objects[id].presentation
      }

      // calculate the technical score
      if (scores_objects.final_technical_score_length > 0) {
        scores_objects.final_technical_score = (scores_objects.pre_final_technical_score /
          scores_objects.final_technical_score_length);
      } else {
        scores_objects.final_technical_score = 0;
      }

      // calculate the avg of the total line scores
      if (scores_objects.final_technical_score_length > 0) {
        scores_objects.avg_total_line_scores = (scores_objects.total_line_scores /
          scores_objects.final_technical_score_length);
      } else {
        scores_objects.avg_total_line_scores = 0;
      }

      scores_objects.final_score = scores_objects.final_technical_score + scores_objects.presentation;
      setBBTReqs(outline, scores_objects, userClass, board_objects, technique_objects);
      return scores_objects;

      function setUserClass(weight, user) {
        let userClass = 6;
        if (!user.weight) {
          user.weight = 99999;
        }
        if (!weight) {
          weight = parseFloat(user.weight);
        }
        if (weight >= 35 && weight <= 49.9) {
          userClass = 1;
        } else if (weight >= 50 && weight <= 70.5) {
          userClass = 2;
        } else if (weight >= 70.6 && weight <= 96.6) {
          userClass = 3;
        } else if (weight >= 96.7 && weight <= 127.5) {
          userClass = 4;
        } else if (weight >= 127.6 && weight <= 164.9) {
          userClass = 5;
        } else if (weight >= 165) {
          userClass = 6;
        } else {
          userClass = 6;
        }
        return userClass;
      }
      function setPresentation(outline) {
        let presentation = 0;
        let judgeScores = [];
        let allJudges = true;
        const j1 = parseFloat(outline.j1);
        const j2 = parseFloat(outline.j2);
        const j3 = parseFloat(outline.j3);
        const j4 = parseFloat(outline.j4);
        const j5 = parseFloat(outline.j5);

        if (j1 > 0) {
          judgeScores.push(j1);
        } else {
          allJudges = false;
        }
        if (j2 > 0) {
          judgeScores.push(j2);
        } else {
          allJudges = false;
        }
        if (j3 > 0) {
          judgeScores.push(j3);
        } else {
          allJudges = false;
        }
        if (j4 > 0) {
          judgeScores.push(j4);
        } else {
          allJudges = false;
        }
        if (j5 > 0) {
          judgeScores.push(j5);
        } else {
          allJudges = false;
        }

        if (allJudges) {
          const min = Math.min(...judgeScores);
          const max = Math.max(...judgeScores);
          let min_dropped = false;
          let max_dropped = false;
          for (let i = 0; i < judgeScores.length; i += 1) {
            let judge_score = judgeScores[i];
            if (min === judge_score && !min_dropped) {
              judgeScores.splice(i, 1);
              min_dropped = true;
            }
            if (max === judge_score && !max_dropped) {
              judgeScores.splice(i, 1);
              max_dropped = true;
            }
          }
        }
        const sum = judgeScores.reduce((a, b) => a + b, 0);
        presentation = (sum / judgeScores.length) || 0;
        return presentation;
      }
      function setBoards(outline, scores_object) {
        const board_objects = {};
        // loop through each of the technique to outlines
        // figure out how many boards we have, add them together
        if (outline && outline.breaking_techniques_to_outlines) {
          for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
            if (technique_to_outline.breaking_technique) {
              board_objects[technique_to_outline.id] = {};
              board_objects[technique_to_outline.id].additional_boards = 0;
              const technique = technique_to_outline.breaking_technique;
              const num_boards = parseInt(technique_to_outline.num_boards);
              const board_multiplier = parseInt(technique.board_multiplier);
              board_objects[technique_to_outline.id].additional_boards = (num_boards / board_multiplier) - 1;
              scores_object.total_boards += num_boards;
            }
          }
        }
        return board_objects;
      }
      function setBoardSizeObjects(outline) {
        const board_size_objects = {};
        if (outline && outline.breaking_techniques_to_outlines) {
          for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
            const id = technique_to_outline.id;
            board_size_objects[id] = {};
            board_size_objects[id] = {
              order: 1,
              name: '',
            };
            const board_id = technique_to_outline.board_id;
            for (const board of VO.dropdown_boards) {
              if (board_id === board.id) {
                board_size_objects[id] = {
                  order: board.order,
                  name: board.name,
                };
              }
            }
          }
        }
        return board_size_objects;
      }
      function setSupportOptions(outline, scores_objects) {
        let support_option_objects = {};
        // loop through the modifiers
        if (outline && outline.breaking_techniques_to_outlines) {
          for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
            technique_to_outline.speed_break_color = 'col-sm-2 text-center';
            const dropdown_modifier_id = technique_to_outline.dropdown_modifier_id;
            if (technique_to_outline.breaking_technique) {
              const id = technique_to_outline.id;
              support_option_objects[id] = {};
              support_option_objects[id] = {
                a_minus: 0,
                point_value: 0,
              };
              for (const modifier of VO.dropdown_support_options) {
                if (modifier && (modifier.id === dropdown_modifier_id)) {
                  support_option_objects[id].a_minus = modifier.a_minus;
                  support_option_objects[id].point_value = modifier.point_value;
                }
                const support_option_id = technique_to_outline.dropdown_modifier_id;
                if (modifier.speed_break && modifier.id === support_option_id) {
                  technique_to_outline.speed_break = modifier.speed_break;
                  scores_objects.total_speed_breaks += 1;
                }
              }
            }
          }
        }
        return support_option_objects;
      }
      function setTechniqueSelections(outline) {
        const technique_objects = {};
        if (outline && outline.breaking_techniques_to_outlines) {
          for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
            const id = technique_to_outline.id;
            if (technique_to_outline.breaking_technique_id && technique_to_outline.breaking_technique) {
              const technique = technique_to_outline.breaking_technique;
              const base_point_value = parseInt(technique.base_point_value);
              const board_multiplier = parseInt(technique.board_multiplier);
              const a_minus = parseInt(technique.a_minus);
              const isPowerBreak = technique.is_power_break;
              technique_objects[id] = {};
              technique_objects[id] = {
                'base_point_value': base_point_value,
                'board_multiplier': board_multiplier,
                'a_minus': a_minus,
                'is_power_break': isPowerBreak,
              };
            } else {
              technique_objects[id] = {
                'base_point_value': 0,
                'board_multiplier': 0,
                'a_minus': 0,
                'is_power_break': false,
              }
            }
          }
        }
        return technique_objects;
      }
      function setDirectionSelections(outline) {
        const direction_objects = {};
        if (outline && outline.breaking_techniques_to_outlines) {
          for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
            const id = technique_to_outline.id;
            const direction_id = technique_to_outline.direction_id;
            direction_objects[id] = {};
            direction_objects[id] = {
              'direction_id': direction_id,
              'a_minus': 0,
              'additional_point_value': 0
            };
            if (direction_id) {
              for (const direction of VO.allDirections) {
                if (direction_id === direction.id) {
                  const a_minus = parseInt(direction.a_minus);
                  const additional_point_value = parseInt(direction.additional_point_value);
                  direction_objects[id] = {
                    'direction_id': direction_id,
                    'a_minus': a_minus,
                    'additional_point_value': additional_point_value
                  };
                }
              }
            }
          }
        }
        return direction_objects;
      }
      function setModifiers(outline) {
        const modifier_objects = {};
        let successive_mods = 0;
        // sets each modifier in the objects
        if (outline && outline.breaking_techniques_to_outlines) {
          for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
            const id = technique_to_outline.id;
            // set the modifiers to nothing for starting out
            modifier_objects[id] = [];

            if (technique_to_outline.blindfolded) {
              technique_to_outline.speed_break = false;
              for (const modifier of VO.checkbox_modifiers) {

                if (modifier && (modifier.name === 'blindfolded')) {
                  const a_minus = parseInt(modifier.a_minus);
                  const point_value = parseInt(modifier.point_value);
                  const name = modifier.description;
                  const mod_item = {
                    'a_minus': a_minus,
                    'point_value': point_value,
                    'name': name,
                  };
                  modifier_objects[id].push(mod_item);
                }
              }
            }

            if (technique_to_outline.self_held) {
              for (const modifier of VO.checkbox_modifiers) {
                if (modifier && (modifier.name === 'self_held')) {
                  const a_minus = parseInt(modifier.a_minus);
                  const point_value = parseInt(modifier.point_value);
                  const name = modifier.description;
                  const mod_item = {
                    'a_minus': a_minus,
                    'point_value': point_value,
                    'name': name,
                  };
                  modifier_objects[id].push(mod_item);
                }
              }
            }
            if (technique_to_outline.simultaneous) {
              for (const modifier of VO.checkbox_modifiers) {
                if (modifier && (modifier.name === 'simultaneous')) {
                  const a_minus = parseInt(modifier.a_minus);
                  const point_value = parseInt(modifier.point_value);
                  const name = modifier.description;
                  const mod_item = {
                    'a_minus': a_minus,
                    'point_value': point_value,
                    'name': name,
                  };
                  modifier_objects[id].push(mod_item);
                }
              }
            }

            // if marked successive, give them 1 point per mark
            if (technique_to_outline.successive) {
              for (const modifier of VO.checkbox_modifiers) {
                if (modifier && (modifier.name === 'successive')) {
                  successive_mods += 1;
                  if (successive_mods === 1) {
                    const mod_item = {
                      'name': modifier.description,
                      'a_minus': 2,
                      'point_value': 0,
                    };
                    // technique_item.board_plus_minus += 2;
                    modifier_objects[id].push(mod_item);
                  } else if (successive_mods === 2) {
                    const mod_item = {
                      'name': modifier.description,
                      'a_minus': 2,
                      'point_value': 1,
                    };
                    modifier_objects[id].push(mod_item);
                  } else if (successive_mods >= 3) {
                    const mod_item = {
                      'name': modifier.description,
                      'a_minus': 2,
                      'point_value': 3,
                    };
                    modifier_objects[id].push(mod_item);
                  }
                }
              }
            }
            if (technique_to_outline.no_spacers) {
              for (const modifier of VO.checkbox_modifiers) {
                if (modifier && (modifier.name === 'no_spacers')) {
                  const a_minus = parseInt(modifier.a_minus);
                  const point_value = parseInt(modifier.point_value);
                  const name = modifier.name;
                  const mod_item = {
                    'a_minus': a_minus,
                    'point_value': point_value,
                    'name': name,
                  };
                  modifier_objects[id].push(mod_item);
                }
              }
            }
          }
        }
        return modifier_objects;
      }
      function setPenalties(outline) {
        const penalties_objects = {};
        if (outline && outline.breaking_techniques_to_outlines) {
          for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
            const id = technique_to_outline.id;
            penalties_objects[id] = [];
            if (technique_to_outline.attempts >= 1) {
              for (const penalty of VO.penalties) {
                if (penalty && (penalty.name === 'attempts')) {
                  const penalty_point_value = parseInt(penalty.point_value);
                  const attempts = parseInt(technique_to_outline.attempts);
                  const tmpPenalty = {
                    point_value: penalty_point_value,
                    attempts,
                    name: penalty.name,
                  };
                  penalties_objects[id].push(tmpPenalty);
                }
              }
            }

            if (technique_to_outline.changed_break) {
              for (const penalty of VO.penalties) {
                if (penalty && (penalty.name === 'changed_break')) {
                  const penalty_point_value = parseInt(penalty.point_value);
                  const tmpPenalty = {
                    point_value: penalty_point_value,
                    name: penalty.name,
                  };
                  penalties_objects[id].push(tmpPenalty);
                }
              }
            }

            if (parseInt(technique_to_outline.dropped_a_board) > 0) {
              for (const penalty of VO.penalties) {
                if (penalty && (penalty.name === 'dropped_board')) {
                  const penalty_point_value = parseInt(penalty.point_value);
                  const tmpPenalty = {
                    point_value: penalty_point_value,
                    name: penalty.name,
                  };
                  penalties_objects[id].push(tmpPenalty);
                }
              }
            }

            if (technique_to_outline.partial_break) {
              for (const penalty of VO.penalties) {
                if (penalty && (penalty.name === 'partial_break')) {
                  const penalty_point_value = parseInt(penalty.point_value);
                  const tmpPenalty = {
                    point_value: penalty_point_value,
                    name: penalty.name,
                  };
                  penalties_objects[id].push(tmpPenalty);
                }
              }
            }
            if (technique_to_outline.didnt_break) {
              for (const penalty of VO.penalties) {
                if (penalty && (penalty.name === 'no_break')) {
                  const penalty_point_value = parseInt(penalty.point_value);
                  const tmpPenalty = {
                    point_value: penalty_point_value,
                    name: penalty.name,
                  };
                  penalties_objects[id].push(tmpPenalty);
                }
              }
            }
          }
        }
        return penalties_objects;
      }
      function setBBTReqs(outline, scores_objects, userClass, board_objects, technique_objects) {
        scores_objects.total_power_breaks = 0;
        for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
          let technique_id = null;
          if (technique_to_outline.breaking_technique_id) {
            technique_id = technique_to_outline.breaking_technique_id;
          }
          let board_id = null;
          if (technique_to_outline.board_id) {
            board_id = technique_to_outline.board_id;
          }
          // first get the board size of the technique_to_outline
          let board_size = 0;
          for (const board of VO.dropdown_boards) {
            if (board.id === board_id) {
              board_size = parseInt(board.order);
            }
          }

          let possible_power_break = false;
          for (const technique of VO.dropdown_techniques) {
            if (technique_id === technique.id) {
              if (technique.is_power_break) {
                possible_power_break = true;
              }
            }
          }

          // this.board_objects[technique_to_outline.id].additional_boards = (num_boards / board_multiplier) - 1;
          // order - weight class is if it's a power break or not
          if (possible_power_break) {
            let power_break_num = board_size - userClass;
            if (power_break_num >= 2) {
              // yay, power break!
              scores_objects.total_power_breaks += 1;
              technique_to_outline.color = 'col-sm-1 text-center border border-primary';
            } else if (power_break_num >= 0) {
              technique_to_outline.color = 'col-sm-1 text-center';
              if (board_objects[technique_to_outline.id].additional_boards >= 1) {
                scores_objects.total_power_breaks += 1;
                technique_to_outline.color = 'col-sm-1 text-center border border-primary';
              }
            } else if (power_break_num < 0) {
              power_break_num += (scores_objects[technique_to_outline.id].a_minus +
                technique_objects[technique_to_outline.id].a_minus);
              if (power_break_num < 0) {
                technique_to_outline.color = 'col-sm-1 text-center border border-warning';
              } else {
                technique_to_outline.color = 'col-sm-1 text-center';
              }
            } else {
              technique_to_outline.color = 'col-sm-1 text-center';
            }
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
