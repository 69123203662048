<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-md-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">My Outlines</h4>
                <p class="card-category">
                  Create or Edit Your Outlines
                </p>
                <p class="card-category">
                  <el-button type="primary" @click="createNewOutline('outline')" icon="">Create New Outline</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterOutlines"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;">
                <el-button type="primary" @click="filterOutlines" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table stripe :data="filteredOutlines" style="width: 100%" height="65vh" @sort-change="handleSortChange">
        <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
          <template v-slot="scope">
            <div class="row">
              <div class="col-sm-4">
                {{ scope.row.order }}
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
              </div>
              <div class="col-sm-4">
                <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="description" sortable="custom" label="Description" :min-width="300" align="center">
          <template v-slot="scope">
            <el-input @change="updateOutlineDescription(scope.row.id, scope.row.description)" v-model="scope.row.description"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Registered" :min-width="150" align="center">
          <template v-slot="scope">
            <el-button v-if="scope.row.locked" type="primary" @click="registerOutline(scope.row.id, scope.row.description)" icon="" disabled>Register</el-button>
            <el-button v-else type="primary" @click="registerOutline(scope.row.id, scope.row.description)" icon="">Register</el-button>
          </template>
        </el-table-column>
        <el-table-column label="Outline" :min-width="150" align="center">
          <template v-slot="scope">
            <outline :outline_id="scope.row.id"></outline>
          </template>
        </el-table-column>
        <el-table-column :min-width="200" align="center">
          <template v-slot="scope">
            <el-dialog
              center
              title="Confirm Delete?"
              :visible.sync="modals.delete">
              <div class="text-center">
                <span>Are you sure you wish to delete the outline: {{ deleteOutlineName }}?</span>
                <br>
                <span slot="footer" class="dialog-footer">
                  <el-button type="success" @click="deleteOutlineDo" icon="">Confirm</el-button>
                  <el-button type="danger" @click="closeModal('delete')" icon="">Cancel</el-button>
                </span>
              </div>
            </el-dialog>
            <el-button type="primary" @click="copyOutline(scope.row.id)" icon="">Copy</el-button>
            <el-button type="danger" @click="deleteOutline(scope.row.id, scope.row.description)" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </card>
    <el-dialog
      center
      title="Are you sure you want to register this outline?"
      :visible.sync="modals.register">
      <card>
        <div class="row">
          <el-select filterable v-model="selectedEventID" placeholder="Select Event" style="width: 100%"
                     @input="getCodesForEvent">
            <el-option v-for="item in events"
                       :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span v-html="register_text" style="font-weight: bold; font-size: 1em;"></span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="row">
            <div class="col-md-4">
              <el-button v-if="!registerReady" type="success" @click="registerOutlineSave()" icon="" disabled>Register</el-button>
              <el-button v-else type="success" @click="registerOutlineSave()" icon="">Register</el-button>
            </div>
            <div class="col-md-6"></div>
            <div class="col-md-2">
              <el-button type="danger" @click="closeModal('register')" icon="">Cancel</el-button>
            </div>
          </div>
        </span>
      </card>
    </el-dialog>
    <el-dialog
      center
      title="Create New Outline"
      :visible.sync="modals.outline">
      <div class="row text-center">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
          <label>Outline Description Name&nbsp;</label>
          <input class="form-control" placeholder="Description"
                 v-model="outline_description">
        </div>
        <div class="col-md-4"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="row">
          <div class="col-sm-6">
            <el-button type="success" @click="saveOutline('outline')" icon="">Save</el-button>
          </div>
          <div class="col-sm-6">
            <el-button type="danger" @click="closeModal('outline')" icon="">Cancel</el-button>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Input, Button, Select, Option, Table, TableColumn } from 'element-ui';
import Outline from "src/pages/Dashboard/Pages/Outline/Outline";

import moment from 'moment';
import OutlinesAPIService from "src/servicehandlers/OutlinesAPIService";
import EventsAPIService from "../../../../servicehandlers/EventsAPIService";
import OrderNumbersAPIService from "../../../../servicehandlers/OrderNumbersAPIService";
// import { Checkbox, Table as LTable } from "../../../../components";
const outlineAPIService = new OutlinesAPIService();
const eventAPIService = new EventsAPIService();
const orderNumbersAPIService = new OrderNumbersAPIService();

export default {
  name: "UserOutline",
  components: {
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Outline,
  },
  data() {
    return {
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false,
        delete: false,
        outline: false,
        okmodal: false,
        error: false,
        register: false,
      },
      registered_events: {},
      canSave: true,
      outline_description: null,
      outlineColumns: ['Order', 'Description', 'Event', 'Edit', 'Action'],
      outlines: [],
      filteredOutlines: [],
      deleteOutlineID: null,
      deleteOutlineName: null,
      orderCodesFound: false,
      selectedOutlineId: null,
      selectedOutlineName: null,
      selectedOrderID: null,
      selectedEventID: null,
      orderNumber: null,
      events: [],
      registerReady: false,
      spinning: false,
      error_txt: null,
      register_text: null,
      success_txt: null,
      search: '',
    }
  },
  mounted() {
    this.getOutlines();
    this.getEventsForUser();
  },
  methods: {
    copyOutline(outline_id) {
      this.spinning = true;
      // create a copy of the outline
      return outlineAPIService.copyOutline(outline_id, this.$router)
      .then((outline) => {
        this.spinning = false;
        this.getOutlines();
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error while retrieving outline for user: ' + error;
        this.openModal('error');
      });
    },
    async isRegistered(event_id) {
      const userId = this.$store.getters.userId;
      this.spinning = true;
      return orderNumbersAPIService.isRegistered(event_id, userId, this.$router)
      .then((registered) => {
        this.spinning = false;
        if (registered === 1) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error while checking if registered: ' + error;
        this.openModal('error');
      });
    },
    getCodesForEvent() {
      const userId = this.$store.getters.userId;
      this.spinning = true;
      if (!this.selectedEventID) {
        return false;
      }
      this.registerReady = true;
      return orderNumbersAPIService.getUnusedOrderForEvent(this.selectedEventID, userId, this.$router)
      .then((orderNumber) => {
        this.orderNumber = orderNumber.id;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error while retrieving codes for event: ' + error;
        this.openModal('error');
      });
    },
    getEventsForUser() {
      const userId = this.$store.getters.userId;
      this.spinning = true;
      return eventAPIService.getEventsForUser(userId, this.$router)
      .then((userEvents) => {
        this.events = userEvents;
        for (const event of userEvents) {
          event.text = event.description;
          event.value = event.id;
        }
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error: ' + error;
        this.openModal('error');
      });
    },
    registerOutline(outline_id, outline_description) {
      this.selectedOutlineId = outline_id;
      this.selectedOutlineName = outline_description;
      const keys = Object.keys(this.registered_events);
      for (const key of keys) {
        if (parseInt(key) === parseInt(this.selectedOutlineId)) {
          this.selectedOrderID = this.registered_events[key];
          this.selectedEventID = key;
        }
      }
      this.register_text = `Are you sure you want to lock outline: "${this.selectedOutlineName}" to the event?<br>No changes will be allowed once you click register.`;
      this.openModal('register');
    },
    async isEventLocked() {
      return eventAPIService.getEvent(this.selectedEventID, this.$router)
      .then((event) => {
        if (event && event.locked) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error while getting locked or not: ' + error;
        this.openModal('error');
      });
    },
    async registerOutlineSave() {
      const registered = await this.isRegistered(this.selectedEventID);
      if (registered) {
        this.register_text = 'The event is already registered with an outline.  You can copy the outline and edit the copy.';
        this.registerReady = false;
        return;
      }
      const locked = await this.isEventLocked(this.selectedEventID);
      if (locked) {
        this.register_text = 'The event is already locked.  No further outlines can be added';
        this.registerReady = false;
        return;
      }
      this.spinning = true;
      this.register_text = null;
      this.closeModal('register');
      const parms = {
        outline_id: this.selectedOutlineId,
        event_id: this.selectedEventID,
      }
      return orderNumbersAPIService.updateOrderNumber(this.orderNumber, parms, this.$router)
      .then(() => {
        const parms = {
          locked: true,
        };
        return outlineAPIService.updateSingleOutline(this.selectedOutlineId, parms, this.$router);
      })
      .then(() => {
        this.getOutlines();
        this.getEventsForUser();
        this.spinning = false;
        this.success_txt = 'Successfully registered outline with event';
        this.openModal('success');
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error while registering outline: ' + error;
        this.openModal('error');
      });
    },
    deleteOutline(outline_id, outline_name) {
      this.deleteOutlineName = outline_name;
      this.deleteOutlineID = outline_id;
      this.openModal('delete');
    },
    deleteOutlineDo() {
      return outlineAPIService.deleteOutline(this.deleteOutlineID, this.$router)
      .then(() => {
        this.getOutlines();
        this.closeModal('delete');
      })
      .catch((error) => {
        this.closeModal('delete');
        this.error_txt = 'Error deleting outline: ' + error;
        this.openModal('error');
      })
    },
    getOutlines() {
      this.spinning = true;
      const userId = this.$store.getters.userId;
      return outlineAPIService.getOutlineByUser(userId, this.$router)
      .then((resp) => {
        this.spinning = false;
        this.outlines = resp;
        this.filteredOutlines = this.outlines
        this.updateOrder()
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting outlines for the user ' + error;
        this.openModal('error');
      });
    },
    saveOutline(name) {
      this.modals[name] = false;
      this.modals['outline'] = false;
      const userId = this.$store.getters.userId;
      const newOutline = {
        user_id: userId,
        description: this.outline_description,
      };
      return outlineAPIService.addOutline(newOutline, this.$router)
      .then(() => {
        this.getOutlines();
        this.outline_name = null;
        this.outline_description = null;
      })
      .catch((error) => {
        this.error_txt = 'Error saving outline: ' + error;
        this.openModal('error');
      });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    updateOutlineDescription(id, description) {
      const parms = {
        description,
      };
      return outlineAPIService.updateSingleOutline(id, parms, this.$router)
      .then(() => {
        // this.outlineData.sort((a, b) => (a.order > b.order) ? 1 : -1);
      })
      .catch((error) => {
        this.error_txt = 'Error during outline save: ' + error;
        this.openModal('error');
      });
    },
    createNewOutline(name) {
      this.modals[name] = true
    },
    reorderMainOutline(outline_id, order) {
      const parms = {
        order
      };
      return outlineAPIService.updateSingleOutline(outline_id, parms, this.$router)
      .then(() => {
        this.outlineData.sort((a, b) => (a.order > b.order) ? 1 : -1);
      })
      .catch((error) => {
        this.error_txt = 'Error during outline reorder: ' + error;
        this.openModal('error');
      });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.outlines[index];
        this.outlines.splice(index, 1);
        this.outlines.splice(index - 1, 0, temp);
        this.updateOrder()
      }
    },
    moveDown(index) {
      if (index < this.outlines.length - 1) {
        const temp = this.outlines[index];
        this.outlines.splice(index, 1);
        this.outlines.splice(index + 1, 0, temp);
        this.updateOrder()
      }
    },
    updateOrder(){
      for (let i = 0; i < this.outlines.length; i++){
        this.outlines[i].order = i+1
        this.reorderMainOutline(this.outlines[i].id, this.outlines[i].order)
      }
      this.filteredOutlines = this.outlines
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredOutlines.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredOutlines.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterOutlines(){
      try {
        this.filteredOutlines = this.outlines.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
  }
}
</script>

<style scoped>

</style>
